import Vue from 'vue'
import { make } from 'vuex-pathify'
import { Storage } from 'aws-amplify';

import { normalizeFile } from "./file.schema.js";
import * as firebase from '@/firebase.js'

const state = {
  files: {},
  search_input: "",
  timestamp: Date.now(),
}

const getters = {
  file_list: state => {
    return Object.keys(state.files).map(id=>{
      return state.files[id];
    })
  },
  file_count: state => {
    return Object.keys(state.files).length
  },
  files_pending_download: (state, getters) => {
    return getters.file_list.filter(file=>(
      file.downloading
    )).length
  },
  file_list_by_category: (state, getters) => category => {
    return getters.file_list.filter(file=>{
      if(category === 'other'){
        return file.category ? file.category.toLowerCase() === category.toLowerCase() : true;
      }else{
        return file.category ? file.category.toLowerCase() === category.toLowerCase() : false;
      }
    })
  }
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
  setFile: (state, file) => {
    Vue.set(state.files, file.id, normalizeFile(file))
  },
  updateFile: (state, file) => {
    if(state.files[file.id]){
      Vue.set(state.files, file.id, Object.assign(state.files[file.id], file))
    }else{
      Vue.set(state.files, file.id, normalizeFile(file))
    }
  },
  removeFile: (state, file) => {
    Vue.delete(state.files, file.id)
  }
}

const actions = {
  loadFiles: ({commit, rootState}) => {
    console.log("loadFiles")
    return new Promise((resolve, reject)=>{
      firebase.storage.ref(`${rootState.application.id}/${rootState.auth.user.uid}`).listAll().then((res)=>{
        res.items.forEach((itemRef)=>{
          itemRef.getMetadata().then(response => {
            console.log(response)
            commit('setFile', {
              id: response.name,
              name: response.name,
              category: response.customMetadata?.category,
              downloading: false,
            })
          })
        });
      }).catch(e => {
        console.log(e)
      })
      
      // Storage.list('files/', { level: 'private' }).then(result => {        
      //   Promise.all(result.map(obj=>{
      //     commit('setFile', {
      //       id: obj.key,
      //       name: obj.key.substr(obj.key.indexOf('/')+1),
      //       downloading: true,
      //     })
      //     return Storage.get(obj.key, { level: 'private', download: true }).then(result => {
      //       commit('updateFile', {
      //         id: obj.key,
      //         category: result.Metadata?.category,
      //         downloading: false,
      //       })
      //     }).catch(err => {
      //       console.log(err)
      //       commit('updateFile', {
      //         id: obj.key,
      //         downloading: false,
      //         error_downloading: err,
      //       })
      //     })
      //   })).then(results=>{
      //     resolve(results)
      //   }).catch(e=>{
      //     reject(e);
      //   })
      // }).catch(err => {
      //   reject(err)
      // });
    })
  },
  saveFile: ({commit, state, rootState}, {file, category}) => {
    return new Promise((resolve, reject)=>{
      // var file_path = `files/${file.name}`;
      console.log(category)
      commit('setFile', {
        id: file.name,
        name: file.name,
        category: category,
        uploading: true,
      });
      firebase.storage.ref(`${rootState.application.id}/${rootState.auth.user.uid}/${file.name}`).put(file, { customMetadata: {category} }).then(response => {
        console.log(response)
        commit('updateFile', {
          id: file.name,
          uploading: false,
        });
        resolve(response);
      }).catch(err => {
        commit('updateFile', {
          id: file.name,
          uploading: false,
          error_uploading: err,
        });
        reject(err);
      })
      // Storage.put(file_path, file, {
      //   level: 'private',
      //   metadata: { category }
      // }).then(result => {
      //   commit('updateFile', {
      //     id: file_path,
      //     uploading: false,
      //   });
      //   resolve(result);
      // }).catch(err => {
      //   commit('updateFile', {
      //     id: file_path,
      //     uploading: false,
      //     error_uploading: err,
      //   });
      //   reject(err);
      // });
    })
  },
  deleteFile: ({commit, state, rootState}, file)=>{
    commit('confirm', {
      title: "Permanently Delete File?",
      description: "This action cannot be reversed.",
      start_label: "Delete",
      stop_label: "Back",
      start: ()=>{
        commit("removeFile", file)
        firebase.storage.ref(`${rootState.application.id}/${rootState.auth.user.uid}/${file.name}`).delete()
      },
      stop: ()=>{}
    }, {root: true});
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
