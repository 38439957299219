<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .home {   
  	#splash-page { 
      height: calc(100vh + 56px);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(0, 242, 102);
      
      background-image: url("~@/assets/background-1.png");
      background-size: cover;
      #gradient{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: linear-gradient(155deg, rgba(0, 242, 102, 1) 0%, rgba(0, 188, 255,1) 100%);
        background-size: cover;
        opacity: 0.6;
        z-index: 0;
      }
  		.center-module { 
        z-index: 1;
        display: flex;
        align-items: center;
        margin-top: -80px;
  			.welcome { 
          color: #FFF;
          margin-right: 30vw;
  				.title { 
            font-size: 38px;
            font-weight: 700;
            margin-bottom: 40px;
            text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  				}
          .auth{
            margin-bottom: 60px;
            .simple-auth{
              .input{
                margin-bottom: 16px;
                input{
                  border: 2px solid #FFF;
                  background-color: #FFF;
                  padding: 12px 20px;
                  border-radius: 4px;
                  font-size: 18px;
                  color: $text;
                  font-weight: 500;
                  width: 450px;
                  box-shadow: $z_depth_1;
                  &:focus{
                    border-color: #FFBD08
                  }
                  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #CCC;
                    opacity: 1; /* Firefox */
                  }
                }
              }
              
              .button{
                display: inline-block;
                background-color: #FFBD08;
                padding: 12px 20px;
                border-radius: 4px;
                font-size: 18px;
                color: #FFF;
                font-weight: 500;
                box-shadow: $z_depth_1;
                &:hover{
                  cursor: pointer;
                }
                &:active{
                  box-shadow: $z_depth_0;
                }
              }
              .check{
                font-size: 32px;
                color: #FFF;
                font-weight: 600;
                margin-bottom: 16px;
              }
            }
          }
  				.bullet { 
            margin: 32px 0px;
            font-size: 26px;
            font-weight: 600;
            display: flex;
            align-items: center;
            text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  					.fa { 
              width: 30px;
              text-align: center;
              margin-right: 24px;
              // font-size: 18px;
  					}
  				}
  			}
  			.sign-up-container { 
          
  			}
  		}
  		#wave { 
        position: absolute;
        width: 100vw;
        bottom: -40px;
        left: 0vw;
        pointer-events: none;
        user-select: none;
        overflow-x: hidden;
        img{
          width: 100vw;
          transform: scaleX(1.1);
        }
  		}
  	}
    #page-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #FFF;
      #limiter{
        max-width: 900px;
      }
    }
    #hook{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      font-weight: 700;
      color: $button;
      text-align: center;
      margin: 70px 0px 90px 0px;
    }
    #how-it-works{
      position: relative;
      width: 900px;
      height: 900px;
      margin: 128px 0px;
      .title{
        font-size: 26px;
        font-weight: 700;
      }
      img{
        position: absolute;
        &#step-flow{
          width: 600px;
          top: 50px;
          left: -60px;
        }
        &#image-1{
          width: 320px;
          top: 0px;
          right: 60px;
        }
        &#image-2{
          width: 320px;
          top: 400px;
          left: 0px;
        }
        &#image-3{
          width: 380px;
          bottom: -70px;
          right: 0px;
        }
      }
      .step{
        position: absolute;
        font-size: 20px;
        &#step-1{
          top: 110px;
          left: 120px;
        }
        &#step-2{
          top: 465px;
          left: 510px;
        }
        &#step-3{
          top: 835px;
          left: 130px;
        }
      }
    }
    #cost{
      margin: 232px 0px 156px 0px;
      .title{
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 32px;
      }
      .card-container{
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        .plan{
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-start;
          width: 430px;
          padding: 20px 24px;
          border-radius: 8px;
          &.custom{
            width: 100%;
          }
          .plan-title{
            font-size: 20px;
            font-weight: 700;
            color: $button;
            margin-bottom: 8px;
          }
          .plan-cost{
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 24px;
          }
          .row{
            display: flex;
            margin-bottom: 12px;
          }
          .bullet-container{
            flex: 1;
            padding-right: 32px;
            .bullet{
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 16px;
              display: flex;
              align-items: flex-start;
              .fa{
                color: $button;
                font-size: 14px;
                margin-right: 12px;
                height: 19px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .button{
            margin-top: 32px;
          }
          .check-input{
            margin-bottom: 0px;
          }
          .row-input{
            display: flex;
            align-items: center;
            margin-left: 34px;
            .textarea-container{
              width: 60px;
              margin-left: 12px;
            }
          }
        }
      }
    }
    #why-us{
      .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &#row-4{
          margin-bottom: 40px;
        }
        &#row-5{
          margin-bottom: 100px;
        }
        &#row-6{
          
        }
        img{
          &#image-4{
            width: 320px;
          }
          &#image-5{
            width: 420px;
            margin-top: -32px;
          }
          &#image-6{
            width: 380px;
          }
        }
        .message-container{
          &#message-container-4{
            width: 370px;
            margin: 0px 32px;
          }
          &#message-container-5{
            width: 320px;
            margin: 0px 32px;
          }
          &#message-container-6{
            width: 370px;
            margin: 0px 32px;
          }
          .header{
            font-size: 26px;
            font-weight: 700;
          }
          .message{
            font-size: 20px;
          }
        }
      }
    }
    #get-started{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 156px 0px 64px 0px;
      .title{
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 32px;
      }
      .start-button{
        width: 300px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $button;
        border-radius: 4px;
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        box-shadow: $z_depth_1;
        &:hover{
          background: $button-hover;
          cursor: pointer;
        }
        &:active{
          background: $button-active;
          box-shadow: $z_depth_0;
        }
      }
    }
  	#footer { 
      height: 500px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(155deg, rgba(0, 242, 102, 1) 0%, rgba(0, 188, 255,1) 100%);
      // background-image: url("~@/assets/background_g-01.png");
      background-size: cover;
      color: #FFF;
      #wave-bottom { 
        pointer-events: none;
        user-select: none;
        position: absolute;
        width: 100vw;
        top: -40px;
        left: 0vw;
        overflow-x: hidden;
        img{
          width: 100vw;
          transform: scaleX(1.1);
        }
  		}
  		.footer-module { 
        margin-top: 100px;
        width: 600px;
  			.learn-more { 
          font-size: 18px;
          font-weight: 600;
  			}
  			.contact { 
          font-size: 34px;
          font-weight: 700;
          margin-bottom: 24px;
  			}
  			.link-container { 
          display: flex;
          align-items: center;
          justify-content: space-between;
  				.col { 
            .logo{
              font-size: 60px;
              font-weight: 700;
            }
            .link{
              margin: 16px 0px;
              font-size: 16px;
              font-weight: 600;
            }
  				}
  			}
  		}
  	}
  }
</style>

<template>
  <div class="home">
    <div id="splash-page">
      <div id="gradient"></div>
      <div class="center-module">
        <div class="welcome">
          <div class="title" v-if="!email_sent">
            Complex Tax Returns<br />
            Done Right
          </div>
          <div class="auth">
            <div class="simple-auth">
              <div class="input-email" v-if="!email_sent">
                <div class="input">
                  <input v-model="email" placeholder="Email" @keyup.enter="login()"/>
                </div>
                <div class="button" @click="login()">
                  Sign Up | Login
                </div>
              </div>
              <div class="pending" v-if="email_sent">
                <div class="check">
                  Please check your email <br />
                  for a secure link to login 
                </div>
                <div class="button" @click="retry()">
                  Retry
                </div>
              </div>
            </div>
          </div>
          <div v-if="!email_sent">
            <div class="bullet">
              <span class="fa fa-file-certificate"></span>Licenced CPA's
            </div>
            <div class="bullet">
              <span class="fa fa-couch"></span>Hastle Free
            </div>
            <div class="bullet">
              <span class="fa fa-comment-dollar"></span>Transparent Pricing
            </div>
          </div>
        </div>
      </div>
      <div id="wave">
        <img src="../assets/wave.svg" />
      </div>
    </div>
    <div id="page-container">
      <div id="limiter">
        <div id="hook" class="section">
          <div class="hook">
            Your time is valuable,<br />
            don't spend it preparing taxes!
          </div>
        </div>
        <div id="how-it-works" class="section">
          <div class="title">
            How It Works
          </div>
          <img id="step-flow" src="../assets/steps.svg"  />
          <div id="step-1" class="step">
            Upload your documents and<br />
            we will take care of the rest.<br />
            Its that simple
          </div>
          <img id="image-1" src="../assets/relax.svg" />
          <div id="step-2" class="step">
            Along the way our CPA’s may reach<br />
            out with additional opportunities <br />
            to save you money. 
          </div>
          <img id="image-2" src="../assets/support.svg" />
          <div id="step-3" class="step">
            We will e-file your return and<br />
            email you PDF copies for your<br />
            own records.
          </div>
          <img id="image-3" src="../assets/camp.svg" />
        </div>
        <div id="cost" class="section">
          <div class="title">
            What does it cost?
          </div>
          <div class="card-container">
            <div class="plan card">
              <div class="plan-title">
                The Entrepreneur
              </div>
              <div class="plan-cost">
                $250
              </div>
              <div class="bullet-container">
                <div class="bullet">
                  <span class="fa fa-check"></span>Single
                </div>
                <div class="bullet">
                  <span class="fa fa-check"></span>We’ll prepare your business’s return
                </div>
                <div class="bullet">
                  <span class="fa fa-check"></span>Less than 25 stock trades
                </div>
              </div>
              <!-- <btn type="outline" stretch="full" @click="">Select</btn> -->
            </div>
            <div class="plan card">
              <div class="plan-title">
                The Family
              </div>
              <div class="plan-cost">
                $350
              </div>
              <div class="bullet-container">
                <div class="bullet">
                  <span class="fa fa-check"></span>Married Couple with 2 kids
                </div>
                <div class="bullet">
                  <span class="fa fa-check"></span>Less than 25 stock trades
                </div>
                <div class="bullet">
                  <span class="fa fa-check"></span>Itemized Deductions
                </div>
              </div>
              <!-- <btn type="outline" stretch="full" @click="">Select</btn> -->
            </div>
          </div>
          <div class="card-container">
            <div class="plan card custom">
              <div class="plan-title">
                Custom
              </div>
              <div class="plan-cost">
                ${{custom_price}}
              </div>
              <div class="row">
                <div class="bullet-container col">
                  <check-input title="Had a job" v-model="pricing.had_a_job.selected"></check-input>
                  <div v-if="pricing.had_a_job.selected" class="row-input">
                    additional W2s
                    <editable-area v-model.number="pricing.had_a_job.count" ></editable-area>
                  </div>
                  <check-input title="Filing jointly with spouse" v-model="pricing.filing_jointly.selected"></check-input>
                  <check-input title="Have dependents" v-model="pricing.have_kids.selected"></check-input>
                  <div v-if="pricing.have_kids.selected" class="row-input">
                    # of dependents
                    <editable-area v-model.number="pricing.have_kids.count"></editable-area>
                  </div>
                  <check-input title="Itemized deductions" v-model="pricing.itemized_deductions.selected"></check-input>
                </div>
                <div class="bullet-container col">
                  <check-input title="Investment account" v-model="pricing.investment_account.selected"></check-input>
                  <div v-if="pricing.investment_account.selected" class="row-input">
                    # of trades
                    <editable-area v-model.number="pricing.investment_account.count"></editable-area>
                  </div>
                  <check-input title="Own rental property" v-model="pricing.rental_property.selected"></check-input>
                  <check-input title="Bought or sold a house" v-model="pricing.sold_house.selected"></check-input>
                </div>
                <div class="bullet-container col">
                  <check-input title="Personal Business Return" v-model="pricing.personal_business_return.selected"></check-input>
                  <check-input title="Small Business Return" v-model="pricing.small_business_return.selected"></check-input>
                  <check-input title="State return" v-model="pricing.state_return.selected"></check-input>
                  <check-input v-if="pricing.state_return.selected" title="State return in Califonia, New York or New Jersey" v-model="pricing.additoanl_state_return.selected"></check-input>
                  <check-input title="City return" v-model="pricing.city_return.selected"></check-input>
                </div>
              </div>
              <!-- <btn type="fill" stretch="full" @click="">Calculate</btn> -->
            </div>
          </div>
        </div>
        <div id="why-us" class="section">
          <div id="row-4" class="row">
            <img id="image-4" src="../assets/money.svg" />
            <div id="message-container-4" class="message-container">
              <div class="header">
                Maximize Your Return
              </div>
              <br />
              <div class="message">
                Unlike cookie cutter online software, a licenced CPA will review your specific case to make sure that you get the largest possible refund.
              </div>
            </div>
          </div>
          <div id="row-5" class="row">
            <div id="message-container-5" class="message-container">
              <div class="header">
                Hassle Free
              </div>
              <br />
              <div class="message">
                Our lives are busy and your time has value. Let us worry about taxes.
              </div>
            </div>
            <img id="image-5" src="../assets/chill.svg" />
          </div>
          <div id="row-6" class="row">
            <img id="image-6" src="../assets/plan.svg" />
            <div id="message-container-6" class="message-container">
              <div class="header">
                About Us
              </div>
              <br />
              <div class="message">
                We are team made up of CPAs, EAs, and tech gurus who believe every American deserves quality, easy, affordable tax preparation. 
                <br /><br />
                Our experience in the old school brick and mortar CPA world taught us that something needed to change. 
              </div>
            </div>
          </div>
        </div>
        <div id="get-started" class="section">
          <div class="title">
            Ready To Get Started?
          </div>
          <div class="start-button">
            Sign Up
          </div>
        </div>
      </div>
    </div>
    <div id="footer">
      <div id="wave-bottom">
        <img src="../assets/wave-bottom.svg" />
      </div>
      <div class="footer-module">
        <div class="learn-more">
          To learn more contact
        </div>
        <div class="contact">
          Sales@Goodbye1040.com
        </div>
        <div class="link-container">
          <div class="col">
            <div class="logo">
              G
            </div>
          </div>
          <div class="col">
            <div class="link">
              Signup
            </div>
            <div class="link">
              Login
            </div>
          </div>
          <div class="col">
            <div class="link">
              FAQ
            </div>
            <div class="link">
              Contact Us
            </div>
          </div>
          <div class="col">
            <div class="link">
              Terms & Risks
            </div>
            <div class="link">
              Privacy Policy & Cookies
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AuthSimple from '@/components/organisms/AuthSimple.vue'
  import CheckInput from "@/components/molecules/CheckInput.vue"
  import FormInput from "@/components/molecules/FormInput.vue"
  import EditableArea from "@/components/atoms/EditableArea.vue"
  import { Items } from "@/store/modules/payment/payment.schema.js"
  import * as firebase from '@/firebase.js'
  
  export default {
    name: 'Home',
    components: { AuthSimple, CheckInput, FormInput, EditableArea },
    data(){
      return {
        email: null,
        email_sent: false,
        pricing: {
          had_a_job: { selected: false, count: 0, price: 200 },
          filing_jointly: { selected: false, count: 1, price: 250 },
          have_kids: { selected: false, count: 0, price: 50 },
          personal_business_return: { selected: false, count: 1, price: 25 },
          investment_account: { selected: false, count: 0, price: 1 },
          itemized_deductions: { selected: false, count: 1, price: 25 },
          rental_property: { selected: false, count: 1, price: 25 },
          sold_house: { selected: false, count: 1, price: 25 },
          state_return: { selected: false, count: 1, price: 50 },
          additoanl_state_return: { selected: false, count: 1, price: 50 },
          city_return: { selected: false, count: 1, price: 50 },
          dependent_return: { selected: false, count: 1, price: 50 },
          small_business_return: { selected: false, count: 1, price: 400 },
        }
      }
    },
    computed: {
      custom_price(){
        return Object.keys(this.pricing).reduce((acc, key)=>{
          if(this.pricing[key].selected){
            if(key == "investment_account"){
              if(this.pricing["investment_account"].count > 25){
                return acc + this.pricing["investment_account"].count;
              }else{
                return acc + 25;
              }
            }else if(key == "had_a_job"){
              if(this.pricing["had_a_job"].count){
                return acc + this.pricing["had_a_job"].price + (this.pricing["had_a_job"].count * 5)
              }else{
                return acc + this.pricing["had_a_job"].price
              }
            }else{
              return acc + (this.pricing[key].count * this.pricing[key].price) 
            }
          }else{
            return acc
          }
        }, 0)
      }
    },
    methods: {
      retry(){
        this.email_sent = false
      },
      login(){
        var actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be in the authorized domains list in the Firebase Console.
          url: 'https://goodbye1040.com/#/GetStarted',
          // This must be true.
          handleCodeInApp: true,
        };
        firebase.auth.sendSignInLinkToEmail(this.email, actionCodeSettings).then(()=>{
          this.email_sent = true
          window.localStorage.setItem('emailForSignIn', this.email);
        }).catch(function(error) {
          console.log(error)
        });
      }
    }
  }
</script>
