<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  #files-page{
    width: 100%;
    min-height: calc(100vh - 56px);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // background: rgb(242,105,142);
    // background: linear-gradient(155deg, rgba(242,105,142,1) 0%, rgba(170,65,226,1) 100%);
    .loading-bar{
      z-index: 100;
    }
    .limiter{
      width: 900px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      padding-top: 100px;
      padding-bottom: 100px;
      min-height: 100vh;
    }
  }
</style>

<template>
  <div id="files-page">
    <!-- <loading-bar class="loading-bar" :progress="progress" color="yellow"></loading-bar> -->
    <div class="limiter">
      <questions></questions>
    </div>
  </div>
</template>

<script>
  import Questions from "@/components/organisms/Questions.vue"
  
  export default{
    components: { Questions },
  }
</script>
