<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid #BBB;
    border-radius: 2px;
    &.active{
      border: 2px solid $button;
      background-color: $button;
    }
    .fa-check{
      font-size: 11px;
      color: #FFF;
    }
    .fa-minus{
      font-size: 11px;
      color: #BBB;
    }
  }
</style>

<template>
  <div class="checkbox" :class="{'active': active_value}" @click="toggleActiveValue()">
    <span v-if="active_value" class="fa fa-check"></span>
  </div>
</template>

<script>
  export default{
    props: ["value"],
    computed: {
      active_value: {
        get(){
          return this.value;
        },
        set(val){
          this.$emit("update:value", val);
          this.$emit("input", val);
        }
      },
    },
    methods: {
      toggleActiveValue(){
        if(this.active_value){
          this.active_value = false;
        }else{
          this.active_value = true;
        }
      }
    },
  }
</script>
