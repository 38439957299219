<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  #files-page{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #FCF9FB;
    min-height: calc(100vh - 56px);
    position: relative;
    .limiter{
      width: 650px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      .container-area{
        margin-top: 64px;
        margin-bottom: 64px;
      }
    }
  }
</style>

<template>
  <div id="files-page">
    <loading-bar class="loading-bar" :progress="downlaod_progress"></loading-bar>
    <div class="limiter">
      <file-uploader></file-uploader>
    </div>
  </div>
</template>

<script>
  import { Storage } from 'aws-amplify';
  import FileUploader from "@/components/organisms/FileUploader.vue"
  import LoadingBar from "@/components/atoms/LoadingBar.vue"
  
  export default{
    components: { FileUploader, LoadingBar },
    computed: {
      downlaod_progress(){
        return 100 - this.$store.get("files/files_pending_download") * ( 100 / this.$store.get("files/file_count"))
      }
    },
  }
</script>
