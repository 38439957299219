<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  #app{
    $nav_width: 260px;
    #side-nav{
      position: fixed;
      top: 0px;
      left: 0px;
      width: $nav_width;
      height: 100vh;
      background-color: #4f575c;
      box-shadow: $z_depth_2;
      padding-top: 80px;
      .link{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 30px;
        color: #FFF;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        &:hover{
          cursor: pointer;
          background-color: #606a70;
        }
        &.active{
          background: #67c786;
        }
        .fa{
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          &.fa-clipboard-user{
            font-size: 22px;
          }
        }
        .notify{
          width: 14px;
          height: 14px;
          background-color: $button;
          border-radius: 7px;
          box-shadow: $z_depth_3;
          position: absolute;
          top: 16px;
          left: 28px;
        }
      }
    }
    #page-container{
      padding-left: $nav_width
    }
  }
</style>

<template>
    <div id="app">
      <div id="side-nav">
        <div class="link" :class="{'active': urlContains('Dashboard')}" @click="$router.push({ name: 'Dashboard' })">
          <span class="fa fa-envelope-open-dollar"></span>Overview
        </div>
        <div class="link" :class="{'active': urlContains('Info')}" @click="$router.push({ name: 'Info' })">
          <span class="fa fa-clipboard-user"></span>My Info
        </div>
        <div class="link" :class="{'active': urlContains('Files')}" @click="$router.push({ name: 'Files' })">
          <span class="fa fa-cabinet-filing"></span>My Files
        </div>
        <!-- <div class="link" :class="{'active': urlContains('Payment')}" @click="$router.push({ name: 'Payments' })">
          <span class="fa fa-credit-card"></span>Payments
          <span v-if="has_due_payments" class="notify"></span>
        </div> -->
        <div class="link" :class="{'active': urlContains('Support')}" @click="$router.push({ name: 'Support' })">
          <span class="fa fa-user-headset"></span>Support
        </div>
      </div>
      <div id="page-container">
        <router-view></router-view>
      </div>
    </div>
</template>

<script>
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import { Auth, API, graphqlOperation, Storage } from 'aws-amplify'
  
  export default {
    name: 'portal',
    computed: {
      has_due_payments(){
        return this.$store.get('payment/pending_items').length > 0
      }
    },
    methods:{
      urlContains(name){
        return this.$route.name.indexOf(name) > -1
      },
    }
  }
</script>
