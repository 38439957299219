<style lang="scss" scoped>
  @import "~@/styles/constants";
  .simple-auth{
    .input{
      margin-bottom: 16px;
      input{
        border: 2px solid #FFF;
        background-color: #FFF;
        padding: 12px 20px;
        border-radius: 10px;
        font-size: 18px;
        color: $text;
        font-weight: 500;
        width: 450px;
        box-shadow: $z_depth_2;
        &:focus{
          border-color: #FFBD08
        }
      }
    }
    
    .button{
      display: inline-block;
      background-color: #FFBD08;
      padding: 12px 20px;
      border-radius: 10px;
      font-size: 18px;
      color: #FFF;
      font-weight: 500;
      box-shadow: $z_depth_2;
      &:hover{
        cursor: pointer;
      }
      &:active{
        box-shadow: $z_depth_0;
      }
    }
    .check{
      font-size: 32px;
      color: #FFF;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
</style>

<template>
  <div class="simple-auth">
    <div class="input-email" v-if="!email_sent">
      <div class="input">
        <input v-model="email" placeholder="Email"/>
      </div>
      <div class="button" @click="login()">
        Sign Up | Login
      </div>
    </div>
    <div class="pending" v-if="email_sent">
      <div class="check">
        Please check your email <br />
        for a secure sign-in link
      </div>
      <div class="button" @click="retry()">
        Retry
      </div>
    </div>
  </div>
</template>

<script>
  import * as firebase from '@/firebase.js'
  
  export default{
    data(){
      return {
        email: null,
        email_sent: false,
      }
    },
    methods: {
      retry(){
        this.email_sent = false
      },
      login(){
        var actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be in the authorized domains list in the Firebase Console.
          url: 'https://goodbye1040.com/#/GetStarted',
          // This must be true.
          handleCodeInApp: true,
        };
        firebase.auth.sendSignInLinkToEmail(this.email, actionCodeSettings).then(()=>{
          this.email_sent = true
          window.localStorage.setItem('emailForSignIn', this.email);
        }).catch(function(error) {
          console.log(error)
        });
      }
    }
  }
</script>
