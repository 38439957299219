import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import SignIn from '../views/SignIn.vue'
import GetStarted from '../views/GetStarted.vue'
import GetUploading from '../views/GetUploading.vue'
import Sign from '../views/Sign.vue'
import Matching from '../views/Matching.vue'
import Payment from '../views/Payment.vue'

import Portal from '../views/Portal.vue'
import Dashboard from '../views/Dashboard.vue'
import Info from '../views/Info.vue'
import Files from '../views/Files.vue'
import Support from '../views/Support.vue'
import Payments from '../views/Payments.vue'

import { ApplicationStatus, ApplicationStatusFlow } from "@/store/modules/application/status.schema.js"

import store from '../store'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    name: 'Home', 
    component: Home 
  },{ 
    path: '/signin', 
    name: 'SignIn', 
    component: SignIn 
  },{ 
    path: '/sign', 
    name: 'Sign', 
    component: Sign 
  },{ 
    path: '/getstarted', 
    name: 'GetStarted', 
    component: GetStarted, 
    meta: { requiresAuth: true} 
  },{ 
    path: '/matching', 
    name: 'Matching', 
    component: Matching, 
    meta: { requiresAuth: true} 
  },{ 
    path: '/getuploading', 
    name: 'GetUploading', 
    component: GetUploading, 
    meta: { requiresAuth: true} 
  },{ 
    path: '/payment', 
    name: 'Payment', 
    component: Payment, 
    meta: { requiresAuth: true} 
  },{ 
    path: '/portal', 
    name: 'Portal', 
    component: Portal, 
    meta: { requiresAuth: true},
    children: [
      {
        path: '/dashboard', 
        name: 'Dashboard', 
        component: Dashboard, 
      },{
        path: '/info',
        name: 'Info', 
        component: Info
      },{
        path: '/files',
        name: 'Files', 
        component: Files
      },{ 
        path: '/support', 
        name: 'Support', 
        component: Support, 
      },{ 
        path: '/payments', 
        name: 'Payments', 
        component: Payments, 
      }
    ]
  },
  // { path: '/about', name: 'About', component: () => import('../views/About.vue') },
]

const router = new VueRouter({
  routes,
})

router.beforeEach(function (to, from, next) { 
  setTimeout(() => {
      window.scrollTo(0, 0);
  }, 400);
  next();
});

router.beforeResolve((to, from, next) => {
  store.dispatch('auth/checkUserSignIn').then(response=>{  
    console.log(response)
    next()
  }).catch(e=>{
    console.log(e);
    if(to.matched.some(record => record.meta.requiresAuth)){
      next({
        path: '/'
      });
    }else{
      next()
    }
  })
})

export default router
