<style lang="scss" scoped>
  @import "~@/styles/constants";
  .form-input{
    .title{
      // font-size: 12px;
      padding: 2px 4px;
      margin-bottom: 4px;
    }
    .input{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      min-height: 36px;
      box-sizing: border-box;
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #DDD;
      box-shadow: $z_depth_0;
      color: $input;
      font-family: 'Montserrat', sans-serif;
      background-color: rgba(0,0,0,0.03);
      &:focus {
        border-color: $input-border-active;
        outline: 0;
      }
      &.edited{
        border-color: $y !important;
      }
      .value{
        flex: 1;
      }
      .fa{
        padding-left: 16px;
      }
    }
  }
</style>

<template>
  <div class="form-input">
    <div class="title">
      {{title}}
    </div>
    <select-option location="right" :options="normalized_options" @selected="$emit('update:value', $event)">
      <div class="input">
        <div class="value">
          {{value}}
        </div>
        <span class="fa fa-caret-right"></span>
      </div>
    </select-option>
  </div>
</template>

<script>
  import SelectOption from "@/components/molecules/SelectOption.vue";
  export default{
    components: { SelectOption },
    props: [ "title", "value", "options" ],
    computed: {
      normalized_options(){
        return Object.values(this.options).map(val=>val)
      }
    }
  }
</script>
