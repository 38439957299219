<style lang="scss">
  @import "~@/styles/constants.scss";
  #auth-module{
    min-width: 300px;
    padding: 32px;
    box-shadow: $z_depth_3;
    position: relative;
    #loading{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #FFF;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      .animation-container{
        width: 128px;
        height: 128px;
      }
    }
    .title-row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .title{
        font-weight: 600;
        font-size: 20px;
      }
      .button{
        
      }
    }
    .auth-form{
      .form-input{
        margin-bottom: 16px;
      }
      .forgot-button{
        margin-top: -16px;
      }
      .button-container{
        margin-top: 32px;
        .button{
          margin-top: 16px;
        }
        .toggle-button{
          
        }
      }
      input{
        color: #444;
      }
    }
    .error{
      margin-top: 16px;
      .fa{
        margin-right: 8px;
        margin-left: 4px;
      }
    }
    .forgot-password{
      margin-top: 8px;
    }
  }
  .fade-loading-enter-active{
    transition: all 0.2s;
  }
  .fade-loading-leave-active{
    transition: all 0.2s;
  }
  .fade-loading-enter{
    opacity: 0;
  }
  .fade-loading-leave-to{
    opacity: 0;
  }
</style>

<template>
  <div id="auth-module" class="card">
    <!-- Loading -->
    <transition name="fade-loading">
      <div id="loading" v-show="loading">
        <div class="loading-container animation-container" :ref="'loading-container'+_uid"></div>
      </div>
    </transition>
    <!-- Sign In -->
    <div id="sign-in-form" class="auth-form" v-if="formState === FormState.signIn">
      <div class="title-row">
        <div class="title">Welcome Back</div>
      </div>
      <form-input v-model="form.email" title="Email" autocomplete="email" key="email-1"></form-input>
      <form-input v-model="form.password" title="Password" type="password" key="password-1"></form-input>
      <div class="button-container">
        <btn ref="sign-in-button" type="fill" stretch="full" @click="signIn()">Login</btn>
        <btn class="toggle-button" type="outline" stretch="full" @click="toggleState()">Sign Up</btn>
      </div>
    </div>
    <!-- Sign Up -->
    <div id="sign-up-form" class="auth-form" v-if="formState === FormState.signUp">
      <div class="title-row">
        <div class="title">Create Account</div>
      </div>
      <form-input v-model="form.email" title="Email" autocomplete="email" key="email-2"></form-input>
      <form-input v-model="form.password" title="Password" type="password" autocomplete="new-password" key="new-password-2"></form-input>
      <form-input v-model="form.confirm_password" title="Confirm Password" type="password" autocomplete="new-password" key="confirm-password-2"></form-input>
      <div class="button-container">
        <btn ref="sign-in-button" type="fill" stretch="full" @click="signUp()">Sign Up</btn>
        <btn class="toggle-button" type="outline" stretch="full" @click="toggleState()">Login</btn>
      </div>
    </div>
    <!-- Verfiy Email -->
    <div id="confirm-sign-up-form" class="auth-form" v-if="formState === FormState.confirmSignUp" key="verify-email">
      <div class="title-row">
        <div class="title">Verify Email</div>
        <btn class="toggle-button" @click="resendSignUpEmail()">Resend Code</btn>
      </div>
      <form-input v-model="form.authCode" title="Verification Code" autocomplete="false" key="code-3"></form-input>
      <div class="button-container">
        <btn ref="sign-in-button" type="fill" stretch="full" @click="confirmSignUp()">Confirm</btn>
      </div>
    </div>
    <!-- Reset Passsword -->
    <div id="forgot-password-form" class="auth-form" v-if="formState === FormState.resetPassword" key="forgot-password">
      <div class="title-row">
        <div class="title">Reset Password</div>
        <btn class="toggle-button" @click="forgotPassword()">Resend Code</btn>
      </div>
      <form-input v-model="form.authCode" title="Verification Code" autocomplete="false" key="code-4"></form-input>
      <form-input v-model="form.password" title="New Password" type="password" autocomplete="new-password" key="password-4"></form-input>
      <form-input v-model="form.confirm_password" title="Confirm New Password" type="password" autocomplete="new-password" key="confirm-4"></form-input>
      <div class="button-container">
        <btn ref="sign-in-button" type="fill" stretch="full" @click="setNewPassword()">Confirm</btn>
      </div>
    </div>
    <div class="error" v-if="error_message">
      <span class="fa fa-exclamation-triangle"></span>{{error_message}}
    </div>
    <btn class="forgot-password" v-if="forgot_password" stretch="link" size="small" @click="forgotPassword()">Frogot Password?</btn>
  </div>
</template>

<script>
  import lottie from 'lottie-web';
  import { AmplifyEventBus } from 'aws-amplify-vue';
  import { API } from 'aws-amplify'
  import { Auth } from 'aws-amplify';
  import { gsap } from "gsap";
  import FormInput from '../molecules/FormInput.vue';
  
  
  import * as firebaseui from 'firebaseui';
  import * as firebase from '@/firebase.js'
  
  var ui = new firebaseui.auth.AuthUI(firebase.auth);

  const FormState = Object.freeze({
    signIn: 1,
    signUp: 2,
    confirmSignUp: 3,
    resetPassword: 4,
  })
  export default {
    name: 'auth',
    components: { FormInput },
    mounted(){
      this.loading_animation = lottie.loadAnimation({
        container: this.$refs['loading-container'+this._uid], // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '../animations/loading-red.json' // the path to the animation json
      });
    },
    data() {
      return {
        FormState,
        formState: localStorage.getItem('previous-user') ? FormState.signIn : FormState.signUp,
        form: {
          email: '',
          password: '',
          confirm_password: '',
          authCode: ''
        },
        loading: false,
        error_message: null,
        forgot_password: false,
        loading_animation: null,
      }
    },
    watch: {
      'loading': function(val){
        if(val){
          this.loading_animation.playSegments([0,30])
        }
      }
    },
    methods: {
      signIn(new_user = false){
        this.clearErrors()
        this.loading = true;
        
        firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password).then((user) => {
          this.loading = false;
          if(new_user){
            this.$router.push({ name: 'GetStarted' })
          }else{
            this.$router.push({ name: 'Dashboard' })
          }
          
        }).catch((error) => {
          this.loading = false;
          // var errorCode = error.code;
          // var errorMessage = error.message;
          console.log(error);
          this.error_message = error.message;
        });
      },
      signUp(){
        this.clearErrors();
        this.validatePassword(()=>{
          this.loading = true;
          firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password).then((user) => {
            this.loading = false;
            this.formState = FormState.confirmSignUp;
          }).catch((error) => {
            this.loading = false;
            console.log(error);
            this.error_message = error.message;
          });
        })
      },
      confirmSignUp(){
        this.loading = true;
        Auth.confirmSignUp(this.form.email, this.form.authCode).then(response=>{
          this.loading = false;
          this.signIn(true);
        }).catch(error=>{
          this.loading = false;
          this.error_message = error.message;
        })
      },
      resendSignUpEmail(){
        this.loading = true;
        Auth.resendSignUp(this.form.email).then(() => {
          this.loading = false;
          alert('Success! Please check your email.')
        }).catch(e => {
          this.loading = false;
          console.log(e);
        });
      },
      forgotPassword(){
        this.formState = FormState.resetPassword;
        this.loading = true;
        Auth.forgotPassword(this.form.email).then(data => {
          this.loading = false;
          alert('Please check your email for a verification code.')
        }).catch(err => {
          this.loading = false;
          console.log(err)
        });
      },
      setNewPassword(){
        this.validatePassword(()=>{
          this.loading = true;
          Auth.forgotPasswordSubmit(this.form.email, this.form.authCode, this.form.password).then(data => {
            this.loading = false;
            this.formState = FormState.confirmSignIn;
          }).catch(err => {
            this.loading = false;
            console.log(err)
          });
        })
      },
      validatePassword(callback){
        if(this.form.password === this.form.confirm_password){
          callback()
        }else{
          this.reject("Passwords do not match.");
        }
      },
      reject(message){
        console.log(this.$refs);
        gsap.fromTo(this.$refs['sign-in-button'].$el, {x:-3}, {x:3, clearProps:"x", repeat:4, duration: 0.1})
        this.error_message = message;
      },
      clearErrors(){
        this.error_message = null;
        this.forgot_password = false;
      },
      toggleState(){
        this.clearErrors();
        this.formState === FormState.signUp ? this.formState = FormState.signIn : this.formState = FormState.signUp
      }
    },
  }
</script>
