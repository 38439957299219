export const ApplicationStatus = Object.freeze({
  created_application: "CREATED_APPLICATION",
  profile_completed: "PROFILE_COMPLETED",
  upload_completed: "UPLOAD_COMPLETED",
  payment_completed: "PAYMENT_COMPLETED",
  matched_with_cpa: "MATCHED_WITH_CPA",
  ready_to_file: "READY_TO_FILE",
  submitted_application: "SUBMITTED_APPLICATION",
})

export const ApplicationStatusFlow = Object.freeze({
  "CREATED_APPLICATION": 1,
  "PROFILE_COMPLETED": 2,
  "UPLOAD_COMPLETED": 3,
  "MATCHED_WITH_CPA": 4,
  "READY_TO_FILE": 5,
  "PAYMENT_COMPLETED": 6,
  "SUBMITTED_APPLICATION": 7,
})

export const ReturnStatus = Object.freeze({
  none: '',
  requested: "Requested",
  in_progress: "In Progress",
  awaiting_info: "Awaiting Info",
  awaiting_payment: "Awaiting Payment",
  ready_to_file: "Ready to File",
  filed: "Filed",
  canceled: "Canceled"
})
