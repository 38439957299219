<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .file-container{
    .title{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .button{
      input{
        font-size: 0px;
      }
    }
    .drag-drop-area{
      min-height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      border: 2px dashed #f2e1ed;
      border-radius: 4px;
      padding: 12px;
      position: relative;
      .file{
        width: 100%;
        display: flex;
        align-items: stretch;
        &:not(:first-child){
          margin-top: 8px;
        }
        .card-handle{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          color: #FFF;
          background-color: $button;
          overflow: hidden;
          font-size: 18px;
        }
        .card-body{
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          width: 100%;
          font-size: 16px;
          .file-name{
            padding: 12px 16px;
          }
          .delete-file{
            height: 100%;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #CCC;
            &:hover{
              color: #888;
              background-color: #EEE;
              cursor: pointer;
            }
          }
        }
      }
      .empty{
        color: #DDC6CC;
        input{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          margin: 0;
          padding: 0;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          border-bottom: 0px solid #FFF;
        }
      }
    }
  }
</style>

<template>
  <div class="file-container">
    <div class="title">
      {{title}}
    </div>
    <div :id="'drop-area:'+_uid" class="drag-drop-area">
      <div class="file card" v-for="file in files">
        <div class="card-handle" :class="getExtension(file.name)">
          <div v-if="file.uploading">
            <spinner-circle></spinner-circle>
          </div>
          <div v-else>
            <span v-if="hasExtension(file.name, ['docx', 'doc'])" class="fa fa-file-word"></span>
            <span v-else-if="hasExtension(file.name, ['pdf'])" class="fa fa-file-pdf"></span>
            <span v-else-if="hasExtension(file.name, ['docx'])" class="fa fa-file-excel"></span>
            <span v-else-if="hasExtension(file.name, ['eml'])" class="fa fa-mail"></span>
            <span v-else-if="hasExtension(file.name, ['png','jpeg','jpg'])" class="fa fa-file-image"></span>
            <span v-else-if="hasExtension(file.name, ['csv','xlsx','xlsm'])" class="fa fa-file-spreadsheet"></span>
            <span v-else-if="hasExtension(file.name, ['zip'])" class="fa fa-file-archive"></span>
            <span v-else class="fa fa-file"></span>
          </div>
        </div>
        <div class="card-body">
          <div class="file-name">
            {{file.name}}
          </div>
          <div class="delete-file" @click="deleteFile(file)">
            <span class="fa fa-times"></span>
          </div>
        </div>
      </div>
      <div class="empty" v-if="!files || files.length === 0">
        Drop Files Here
        <input class="file-input" :accept="getExtList" type="file" @change="handleUpload" multiple/>
      </div>
    </div>
    <btn type="outline" size="small">
      <div class="fa fa-upload"></div>{{!allowMultipleFiles ? 'Upload File' : 'Upload Files'}}
      <input v-if="!allowMultipleFiles" class="file-input" :accept="getExtList" type="file" @change="handleUpload"/>
      <input v-if="allowMultipleFiles" class="file-input" :accept="getExtList" type="file" @change="handleUpload" multiple/>
    </btn>
  </div>
</template>

<script>
  import SpinnerCircle from "@/components/atoms/SpinnerCircle.vue"
  export default{
    components: { SpinnerCircle },
    props: [ "title", "files", "docTypes", "allowMultipleFiles" ],
    mounted(){
      var dropArea = document.getElementById('drop-area:'+this._uid);
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        document.body.addEventListener(eventName, this.preventDefaults, false)
      });
      ['dragenter', 'dragover'].forEach(eventName => {
        document.body.addEventListener(eventName, ()=>{
          this.file_hover = true
        }, false)
      });
      ['dragleave', 'drop'].forEach(eventName => {
        document.body.addEventListener(eventName, ()=>{this.file_hover = false}, false)
      })
      dropArea.addEventListener('drop', this.handleDrop, false)
    },
    methods: {
      getExtList() {
        const typeList = this.docTypes.map(type => `.${type}, `).join('');
        return typeList.substring(0, typeList.length - 2);
      },
      handleDrop(e){
        this.uploadFile(e.dataTransfer.files);
      },
      handleUpload(e){
        this.uploadFile(e.target.files);
      },
      preventDefaults(e){
        e.preventDefault()
        e.stopPropagation()
      },
      uploadFile(files){
        this.$emit("upload", this.filterFileExtension([...files]));
      },
      filterFileExtension(files){
        if(this.docTypes && this.docTypes.length > 0){
          return files.filter(file=>{
            if(this.hasExtension(file.name, this.docTypes)){
              return true
            }else{
              this.$store.commit('confirm', {
                title: "File Type Not Supported",
                description: "Could not upload: "+file.name+". Please choose a file with one of the following extension: "+this.docTypes.reduce((acc, type)=>{
                  return acc+", "+type
                }),
                start_label: "NONE",
                stop_label: "Next",
                start: ()=>{},
                stop: ()=>{}
              });
              return false;
            }
          })
        }else{
          return files;
        }
      },
      hasExtension(file_name, exts){
        return exts.includes(this.getExtension(file_name).toLowerCase())
      },
      getExtension(file_name){
        return file_name.substr(file_name.lastIndexOf('.') + 1)
      },
      deleteFile(file){
        this.$store.dispatch("files/deleteFile", file)
      },
    }
  }
</script>
