<style lang="scss" scoped>
  #matching{
    width: 100%;
    min-height: calc(100vh - 56px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: rgb(242,105,142);
    // background: linear-gradient(155deg, rgba(242,105,142,1) 0%, rgba(170,65,226,1) 100%);
    .card{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 500px;
      height: 300px;
      transition: width 1.2s ease, height 1.2s ease;
      &.relax{
        width: 600px;
        height: 600px;
      }
      .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title{
          font-size: 20px;
          font-weight: 600;
        }
        .max-title{
          font-size: 22px;
          font-weight: 600;
        }
        .sub-title{
          margin-top: 16px;
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: 600;
          width: 380px;
          text-align: center;
        }
        .animation-container{
          width: 128px;
          height: 128px;
        }
        .profile-pic{
          width: 120px;
          height: 120px;
          border-radius: 50%;
          margin-bottom: 16px;
        }
        .image-5{
          width: 400px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .fade-slow-enter-active{
    transition: all 6s;
  }
  .fade-slow-leave-active{
    transition: all 0.4s;
  }
  .fade-slow-enter{
    opacity: 0;
  }
  .fade-slow-leave-to{
    opacity: 0;
  }
</style>

<template>
  <div id="matching">
    <div class="card" :class="{'relax': display_state == DisplayState.relax}">
      <div class="container" v-show="display_state == DisplayState.matching">
        <div class="title">
          Finding Your Perfect CPA
        </div>
        <div class="loading-container animation-container" :ref="'loading-container'+_uid"></div>
      </div>
      <transition name="fade">
        <div v-show="display_state == DisplayState.found">
          <transition name="fade" mode="out-in">
            <div class="container" v-if="!show_cpa" key="matched">
              <div class="title">
                Match Found!
              </div>
              <div class="match-container animation-container" :ref="'match-container'+_uid"></div>
            </div>
            <div class="container" v-else key="cpa">
              <img class="profile-pic" src="../assets/rb-profile.jpeg" />
              <div class="title">
                RB Helterbrand
              </div>
            </div>
          </transition>
        </div>
      </transition>
      <transition name="fade-slow">
        <div class="container" v-show="display_state == DisplayState.relax">
          <div class="max-title">
            Now Relax
          </div>
          <div class="sub-title">
            Your CPA has been notifed and we will begin work on your application shortly. We will reach out by email once your return is ready to file.
          </div>
          <img class="image-5" src="../assets/chill.svg" />
          <btn type="outline" @click="$router.push({ name: 'Dashboard' })">Current Status</btn>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import lottie from 'lottie-web';
  import SpinnerCircle from "@/components/atoms/SpinnerCircle.vue"
  import Cpa from "@/components/molecules/CpaCard.vue"
  import { ApplicationStatus, ApplicationStatusFlow } from "@/store/modules/application/status.schema.js"
  
  const DisplayState = Object.freeze({
    matching: 1,
    found: 2,
    relax: 3,
    void: 4,
  })
  
  export default{
    components: { SpinnerCircle, Cpa },
    mounted(){
      if(this.needsMatch()){
        this.loading_animation = lottie.loadAnimation({
          container: this.$refs['loading-container'+this._uid], // the dom element that will contain the animation
          renderer: 'svg',
          loop: true,
          autoplay: false,
          path: './animations/loading-red.json' // the path to the animation json
        }).playSegments([0,30]);
        this.match_animation = lottie.loadAnimation({
          container: this.$refs['match-container'+this._uid], // the dom element that will contain the animation
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: './animations/star.json' // the path to the animation json
        });
        setTimeout(()=>{
          this.matched();
        }, 6400)
      }else{
        this.display_state = DisplayState.relax;
      }
    },
    data(){
      return{
        DisplayState,
        display_state: DisplayState.matching,
        show_cpa: false,
        loading_animation: null,
        match_animation: null,
      }
    },
    methods: {
      matched(){
        this.display_state = DisplayState.found;
        this.match_animation.play();
        this.$store.commit("application/status", ApplicationStatus.matched_with_cpa);
        setTimeout(()=>{
          this.show_cpa = true;
          setTimeout(()=>{
            this.display_state = DisplayState.void;
            setTimeout(()=>{
              this.display_state = DisplayState.relax;
            }, 500)
          }, 3000)
        }, 1800)
      },
      needsMatch(){
        return ApplicationStatusFlow[this.$store.get("application/status")] < ApplicationStatusFlow[ApplicationStatus.matched_with_cpa]
      }
    }
  }
</script>
