<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .card{
    padding: 40px;
    .title{
      font-size: 20px;
      margin-bottom: 24px;
    }
    .item-container{
      .items{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        .item-name{
          
        }
        .item-cost{
          
        }
      }
    }
    .total{
      border-top: 1px solid #DDD;
      padding: 16px 0px;
      text-align: right;
      font-size: 18px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .credit-card-input{
      #payment-form{
        margin-bottom: 8px;
        #card-element{
          box-shadow: $z_depth_1;
        }
        #card-errors{
            padding: 4px 0px;
        }
      }
      .button{
        // height: 40px;
        width: 100%;
      }
    }
  }
  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
</style>

<template>
  <div class="card">
    <div class="title">
      Balance
    </div>
    <div class="item-container">
      <div class="items" v-for="item in $store.get('payment/pending_items')">
        <div class="item-name">
          {{item.name}}
        </div>
        <div class="item-cost">
          ${{item.cost}}
        </div>
      </div>
    </div>
    <div class="total">
      <div class="name">
        Total
      </div>
      <div class="cost">
        ${{cost}}
      </div>
    </div>
    <div class="credit-card-input">
      <form id="payment-form">
        <div id="card-element" @change="catchErrors"></div>
        <div id="card-errors" role="alert">
          {{error_message}}
        </div>
      </form>
      <btn type="fill" @click="pay()">
        <spinner-circle v-if="loading_payment"></spinner-circle>
        <span v-else>Pay ${{cost}}</span>
      </btn>
    </div>
  </div>
</template>

<script>
  import stripe from "@/stripe";
  import SpinnerCircle from "@/components/atoms/SpinnerCircle.vue"
  // const stripe = new Stripe('pk_test_aKezVVGFoydukVzxqZU1vrFB00HhdXHxCa');
  var elements = stripe.elements();
  var card = elements.create('card');

  export default{
    components: { SpinnerCircle },
    mounted(){
      card.mount("#card-element");
    },
    data(){
      return {
        error_message: null,
        loading_payment: false,
      }
    },
    computed:{
      stripe_amount(){
        return this.$store.get("payment/stripe_total");
      },
      cost(){
        return this.$store.get("payment/pending_total")
      },
      application_loaded(){
        return this.$store.get('application/application_loaded')
      }
    },
    watch: {
      'application_loaded': {
        immediate: true,
        handler(val){
          if(val){
            this.$store.dispatch('payment/createPaymentIntent', this.stripe_amount)
          }
        }
      }
    },
    methods: {
      pay(){
        this.loading_payment = true;
        this.$store.dispatch('payment/pay', card).then(response=>{
          this.loading_payment = false;
          this.$store.commit("application/status", ApplicationStatus.payment_completed)
          // if(ApplicationStatusFlow[this.$store.get("application/status")] < ApplicationStatusFlow[ApplicationStatus.PAYMENT_COMPLETED]){
          //   this.$store.commit("application/status", ApplicationStatus.payment_completed)
          //   this.$router.push({ name: 'Matching' })
          // }
        }).catch(err=>{
          this.loading_payment = false;
          this.error_message = err;
        })
      },
      catchErrors(event){
        if(event.error){
          this.error_message = event.error.message;
        } else {
          this.error_message = '';
        }
      },
    }
  }
</script>
