<style lang="scss">
  @import "~@/styles/constants";
  .check-input{
    display: flex;
    align-items: flex-start;
    margin: 0px -4px 8px -4px;
    padding: 8px;
    &:hover{
      cursor: pointer;
      color: $button;
    }
    &.active{
      
    }
    .no-click{
      pointer-events: none;
      margin-top: 2px;
    }
    .title{
      margin-left: 8px;
      padding: 2px 4px;
    }
  }
</style>

<template>
  <div class="check-input" :class="{active: active_value}"@click="active_value = !active_value">
    <check class="no-click" v-model="active_value"></check>
    <div class="title">
      {{title}}
    </div>
  </div>
</template>

<script>
  import Check from '@/components/atoms/Check.vue';
  export default{
    components: { Check },
    props: [ "title", "value" ],
    computed: {
      active_value: {
        get(){
          return this.value;
        },
        set(val){
          this.$emit("update:value", val);
          this.$emit("input", val);
        }
      },
    },
  }
</script>
