import Vue from 'vue'
import { make } from 'vuex-pathify'
import { Auth } from 'aws-amplify'
import router from '@/router'
import * as firebase from '@/firebase.js'

const state = {
  signed_in: false,
  user: null,
  identity_id: null,
}

const getters = {
  ...make.getters([...Object.keys(state)]),
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
  wasSignedIn(state, user){
    state.signed_in = true;
    state.user = user;
    localStorage.setItem('previous-user', true)
  },
  wasSignedOut(state){
    state.signed_in = false;
    state.user = null;
  }
}

const actions = {
  checkUserSignIn({commit, dispatch, state, rootState}){
    return new Promise((resolve,reject)=>{
      firebase.auth.onAuthStateChanged(function(user) {
        if (user) {
          commit('wasSignedIn', user);
          resolve(user);
          dispatch('application/loadApplication', null, {root: true}).then(() => {
            dispatch('files/loadFiles', null, {root: true});
          });
        } else {
          if(firebase.auth.isSignInWithEmailLink(window.location.href)){
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
              email = window.prompt('Please provide your email for confirmation');
            }
            firebase.auth.setPersistence('local').then(()=>{
              firebase.auth.signInWithEmailLink(email, window.location.href).then((result)=>{
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
                commit('wasSignedIn', result.user);
                resolve(result.user);
                dispatch('application/loadApplication', null, {root: true}).then(() => {
                  dispatch('files/loadFiles', null, {root: true});
                });
              }).catch(function(error) {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
                reject(error)
              });
            }).catch(error=>{
              reject(error)
            })
            
          }else{
            commit('wasSignedOut')
            reject('noAuthProvided');
          }
        }
      });
      
        // if(!rootState.application.application_loaded){
        //   dispatch('fetchCurrentCredentials').then(response=>{
        //     dispatch('files/loadFiles', null, {root: true});
        //     dispatch('application/loadApplication', null, {root: true});
        //   }).catch(e=>{
        //     console.log(e);
        //     dispatch('files/loadFiles', null, {root: true});
        //     dispatch('application/loadApplication', null, {root: true});
        //   })
        // }
        
    })
  },
  // fetchCurrentCredentials({commit}){
  //   return new Promise((resolve,reject)=>{
  //     Auth.currentCredentials().then(response=>{
  //       commit("identity_id", response.data.IdentityId)
  //       resolve(response);
  //     }).catch(e=>{
  //       console.log(e);
  //       reject(e);
  //     })
  //   })
  // },
  signOut({commit}){
    firebase.auth.signOut().then(()=>{
      commit('wasSignedOut');
      router.go();
    }).catch(function(error) {
      console.log(error)
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
