<style lang="scss">
  @import "~@/styles/constants";
  .form-input{
    .title{
      // font-size: 12px;
      padding: 2px 4px;
      margin-bottom: 4px;
    }
    .vdp-datepicker{
      // position: inherit !important;
      &.highlight{
        background-color: #FFFFCC;
      }
      .vdp-datepicker__calendar{
        border: 0px solid #FFF;
        box-shadow: $z_depth_3;
        &:hover{
          cursor: pointer;
        }
        header{
          span{
            background-color: $button;
            color: #FFF;
            &:hover{
              background-color: $button !important;
            }
          }
          .prev::after{
            border-right-color: #FFF !important;
          }
          .next::after{
            border-left-color: #FFF !important;
          }
        }
        .selected{
          background-color: $button !important;
          color: #FFF;
        }
        .day-header{
          color: #AAA;
        }
        .day, .month, .year{
          &.cell{
            border-radius: 1px;
            &:not(.blank){
              &:hover{
                border-color: $button !important;
                // background-color: $b20;
                // color: #FFF;
              }
            }
          }
        }
      }
      input {
        flex: 1;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 16px;
        font-size: 14px;
        border-radius: 3px;
        border: 1px solid #DDD;
        box-shadow: $z_depth_0;
        color: $input;
        font-family: 'Montserrat', sans-serif;
        background-color: rgba(0,0,0,0.03);
        &:focus {
          border-color: $input-border-active;
          outline: 0;
        }
      }
    }
    .input-calendar-icon{
      position: absolute;
      top: 15px;
      right: 6px;
      color: #DDD;
      font-size: 15px;
    }
    input{
      font-size: 15px;
    }
  }
</style>

<template>
  <div class="form-input">
    <div class="title">
      {{title}}
    </div>
    <datepicker v-model="date" :typeable="true" format="MMM d, yyyy"></datepicker>
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  export default{
    components: { Datepicker },
    props: [ "title", "value", "options" ],
    computed: {
      active_value: {
        get(){
          return this.value;
        },
        set(val){
          this.$emit("update:value", val);
          this.$emit("input", val);
        }
      },
      date: {
        get(){
          return this.fromISO(this.active_value);
        },
        set(val){
          this.active_value = this.toISO(val);
        }
      }
    },
    methods: {
      fromISO(val){
        if(val){
          return val.replace(/-/g, '\/');
        }else{
          return ""
        }
      },
      toISO(val){
        if(val){
          var d = new Date(val);
          return d.getFullYear()+'-' + (d.getMonth()+1) + '-'+d.getDate();
        }else{
          return "";
        }
      }
    }
  }
</script>
