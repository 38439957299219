<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  #cpa-page{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #FCF9FB;
    min-height: calc(100vh - 56px);
    .limiter{
      width: 650px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      .cpa-card{
        margin-top: 64px;
        margin-bottom: 0px;
      }
      .status-card{
        margin-top: 32px;
        padding: 32px 80px 64px 80px;
        &:last-of-type{
          margin-bottom: 64px;
        }
        .title{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 32px;
          font-size: 20px;
          font-weight: 600;
        }
        
        .note{
          margin-top: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          .check{
            display: flex;
            padding: 4px 16px;
            &:hover{
              cursor: pointer;
            }
            .checkbox{
              margin-right: 8px;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
</style>

<template>
  <div id="cpa-page">
    <div class="limiter">
      <cpa></cpa>
      <div class="status-card card" v-if='this.$store.get("application/personal_return")'>
        <div class="title">
          Personal Return
        </div>
        <milestones :stages='getStages(this.$store.get("application/personal_return_status"))' :active-index="personal_return_status" /></milestones>
      </div>
      <div class="status-card card" v-if='this.$store.get("application/business_return")'>
        <div class="title">
          Business Return
        </div>
        <milestones :stages='getStages(this.$store.get("application/business_return_status"))' :active-index="business_return_status" /></milestones>
      </div>
      <div class="status-card card" v-if='this.$store.get("application/other_return")'>
        <div class="title">
          Other Return
        </div>
        <milestones :stages='getStages(this.$store.get("application/other_return_status"))' :active-index="other_return_status" /></milestones>
      </div>
    </div>
  </div>
</template>

<script>
  import Check from "@/components/atoms/Check.vue"
  import Cpa from "@/components/molecules/CpaCard.vue"
  import Milestones from "@/components/organisms/Milestones.vue"
  import { ApplicationStatus, ReturnStatus } from "@/store/modules/application/status.schema.js"
  import DropDownArea from "@/components/molecules/DropDownArea.vue"
  import CheckInput from "@/components/molecules/CheckInput.vue"
  export default{
    components: { Check, Cpa, Milestones, DropDownArea, CheckInput },
    data(){
      return {
        ApplicationStatus,
        receive_email_updates: true,
      }
    },
    computed: {
      personal_return_status(){
        return this.getStages(this.$store.get("application/personal_return_status")).indexOf(this.$store.get("application/personal_return_status"))
      },
      business_return_status(){
        return this.getStages(this.$store.get("application/business_return_status")).indexOf(this.$store.get("application/business_return_status"))
      },
      other_return_status(){
        return this.getStages(this.$store.get("application/other_return_status")).indexOf(this.$store.get("application/other_return_status"))
      },
    },
    methods: {
      getStages(status){
        const stages = []
        stages.push(ReturnStatus.requested)
        stages.push(ReturnStatus.in_progress)
        if(status === ReturnStatus.awaiting_info){
          stages.push(ReturnStatus.awaiting_info)
        }
        stages.push(ReturnStatus.awaiting_payment)
        stages.push(ReturnStatus.ready_to_file)
        if(status === ReturnStatus.canceled){
          stages.push(ReturnStatus.canceled)
        }else{
          stages.push(ReturnStatus.filed)
        }
        return stages;
      }
    }
  }
</script>
