<style lang="scss" scoped>
  @import "~@/styles/constants";
  .user-input{
    position: relative;
    display: flex;
    align-items: center;
    input {
      flex: 1;
      max-width: 100%;
      box-sizing: border-box;
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #DDD;
      box-shadow: $z_depth_0;
      color: $input;
      font-family: 'Montserrat', sans-serif;
      background-color: rgba(0,0,0,0.03);
      &:focus {
        border-color: $input-border-active;
        outline: 0;
      }
      &.edited{
        border-color: $y !important;
      }
      &.password{
        font-size: 20px;
        padding: 5px 16px;
      }
      &.icon{
        padding: 8px 36px 8px 16px;
      }
    }
    .fa{
      position: absolute;
      right: 16px;
    }
  }
</style>

<template>
  <div class="user-input input">
    <input v-model="active_value" :class="{edited, icon, 'password': type == 'password'}" :type="type" :autocomplete="autocomplete"/>
    <span v-if="icon" class="fa" :class="icon"></span>
  </div>
</template>

<script>
  export default{
    props: ["value","type", "autocomplete", "icon"],
    created(){
      this.value_copy = this.value;
    },
    data(){
      return {
        value_copy: this.value,
      }
    },
    computed: {
      edited(){
        return this.value_copy != this.value;
      },
      active_value: {
        get(){
          return this.value;
        },
        set(val){
          this.$emit("update:value", val);
          this.$emit("input", val);
        }
      },
    }
  }
</script>
