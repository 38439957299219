<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .card{
    padding: 40px;
    .title{
      font-size: 20px;
      margin-bottom: 24px;
    }
    .item-container{
      .items{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        .item-name{
          
        }
        .item-cost{
          
        }
      }
    }
    .total{
      border-top: 1px solid #DDD;
      padding: 16px 0px 0px 0px;
      text-align: right;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>

<template>
  <div class="card">
    <div class="title">
      Payed ({{purchased_date}})
    </div>
    <div class="item-container">
      <div class="items" v-for="item in items">
        <div class="item-name">
          {{item.name}}
        </div>
        <div class="item-cost">
          ${{item.cost}}
        </div>
      </div>
    </div>
    <div class="total">
      <div class="name">
        Total
      </div>
      <div class="cost">
        ${{cost}}
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    props: [ 'payment' ],
    computed:{
      items(){
        return this.payment.items
        // .map(id=>{
        //   return this.$store.get('payment/purchased_items').find(item=>{
        //     return item.id = id;
        //   })
        // })
      },
      purchased_date(){
        if(this.payment.date){
          var date = new Date(this.payment.date)
          return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
        }else{
          return "unknown date"
        }
      },
      cost(){
        return this.payment.items.reduce((acc, item)=>{
          return acc += item.cost
        }, 0);
      },
    },
    watch: {
      
    },
    methods: {
      
    }
  }
</script>
