<style lang="scss" scoped>
  .address{
    width: 100%;
    margin-bottom: 16px;
    .row{
      display: flex;
      align-items: center;
      &:not(:first-child){
        margin-top: 16px;
      }
      .first{
        flex: 1;
        margin-right: 16px;
      }
      .last{
        flex: 1;
      }
    }
  }
</style>

<template>
  <div class="address">
    <div class="row">
      <form-input class="first" title="First Name"></form-input>
      <form-input class="last" title="Last Name"></form-input>
    </div>
  </div>
</template>

<script>
  import FormInput from "@/components/molecules/FormInput.vue"
  export default{
    components: { FormInput },
  }
</script>
