import { getUniqueID } from "@/lib/id.js"

// NOTES
// - remove any info that exists in document
// - what does it mean to be head of Household
// - swap out filing_status

export function normalizeUser(user){
  return Object.assign({}, newUser(), user)
}

export function newApplication(){
  return  {
    id: getUniqueID(), // ID
    status: null,
    year: new Date().getFullYear(),
    cpa: null,
    created_at: new Date(),
    updated_at: new Date(),
    account_notes: "",
    
    // User
    email: null, // String 
    phone_number: null, // String
    first_name: null, // String
    last_name: null, // String
    dob: null, // String
    ssn: null, // String
    gender: null, // String
    blind: null, // Boolean
    
    personal_return: false,
    personal_return_status: null,
    business_return: false,
    business_return_status: null,
    other_return: false,
    other_return_status: null,
    other_return_description: null,
    
    business_name: null,
    ein: null,
    
    home_address: newAddress(), // Address
    billing_address: newAddress(), // Address
    business_address: newAddress(), // Address
    
    spouse: newUser(), // User
    
    previous_spouse: null, // User
    dependent_user: false, // Boolean
    dependent_spouse: false, // Boolean
    dependents: [], // DependentUser
    
    
    marital_status: null,
    filing_jointly: false,
    
    marital_status_change_this_year: false, // Boolean
    paying_alimony: false, // Boolean
    alimony_paid: null, // String
    paying_alimony_to: newUser(), // User
    receiving_alimony: false, // Boolean
    alimony_received: null, // String
    receiving_alimony_from: newUser(), // User
    bought_sold_house: false, // Boolean
    had_a_job: false, // Boolean
    contract_employee: false,
    foregin_bank_account: false, // Boolean
    additional_info: null, // String
    request_refund_direct_deposit: false, // Boolean
    received_social_security: false, // Boolean
    received_retirement_account_distributions: false, // Boolean
    itemize_deductions: false, // Boolean
    property_tax_amount: null, // String
    own_business: false, // Boolean
    sole_owner: false, // Boolean
    filed_return_for_flow_through_entity: false, // Boolean
    own_rental_property: false, // Boolean
    traded_cryptocurrency: false,
    
    investment_account: false, // Boolean
    interest_on_a_bank_account: false, // Boolean
    receive_royalty_payments: false, // Boolean
    paid_tuition: false, // Boolean
    paid_student_loan_interest: false, // Boolean
    paid_employment_tax_on_household_employee: false, // Boolean
    amount_of_employment_taxes: null, // String
    owned_a_farm: false, // Boolean
  }
}

export function newUser(){
  return {
    id: getUniqueID(), // ID
    email: null, // String 
    phone_number: null, // String
    first_name: null, // String
    last_name: null, // String
    dob: null, // String
    ssn: null, // String
    gender: null, // String
    blind: null, // Boolean
  }
}

export function newDependentUser(){
  return {
    id: getUniqueID(), // ID
    child_care: false, // Boolean
    relationship: null, // String
    student: false, // Boolean
    disabled: false, // Boolean
    months_living_togeather: null, // String
    ...newUser()
  }
}

export function newAddress(){
  return {
    street: "", // String
    city: "", // String
    po_box: "", // String
    apt: "", // String
    state: "", // String
    zip_code: null // Number
  }
}

export const RelationshipStatus = Object.freeze({
  single: "Single",
  married: "Married",
  divorced: "Divorced",
  widow: "Widow",
})

export const FilingStatus = Object.freeze({
  single: "Single",
  married_filing_jointly: "Married filing Jointly",
  married_filing_seperately: "Married Filing Seperately",
  head_of_household: "Head of Household",
  qualified_widow: "Qualified Widow",
})
