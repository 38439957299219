<style lang="scss" scoped>
  .address{
    width: 100%;
    .row{
      display: flex;
      align-items: center;
      &:not(:first-child){
        margin-top: 16px;
      }
      .address{
        flex: 1;
        margin-right: 16px;
      }
      .apt{
        width: 100px;
      }
      .zip{
        width: 100px;
        margin-right: 16px;
      }
      .city{
        flex: 1;
        margin-right: 16px;
      }
      .state{
        width: 80px;
      }
    }
  }
</style>

<template>
  <div class="address">
    <div class="row">
      <form-input class="address" title="Address"></form-input>
      <form-input class="apt" title="Apt"></form-input>
    </div>
    <div class="row">
      <form-input class="zip" title="Zip Code"></form-input>
      <form-input class="city" title="City"></form-input>
      <form-input class="state" title="State"></form-input>
    </div>
  </div>
</template>

<script>
  import FormInput from "@/components/molecules/FormInput.vue"
  export default{
    components: { FormInput },
  }
</script>
