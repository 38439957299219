<style lang="scss" scoped>
  @import "~@/styles/constants";
  .milestones{
    display: flex;
    align-items: center;
    .col{
      width: 38px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .milestone{
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: linear-gradient(155deg, rgba(242,242,242,1) 0%, rgba(217, 217, 217,1) 100%);
        border: 1px solid #BBB;
        &.active{
          background: linear-gradient(155deg, rgba(0, 242, 102, 1) 0%, rgba(0, 188, 255,1) 100%);
          border: 1px solid #36bf81;
          &.alert{
            background: linear-gradient(155deg, #f2d669 0%, #e29041 100%);
            border: 1px solid #e29041;
          }
          .fa{
            display: block;
          }
        }
        .fa{
          display: none;
          color: #FFF;
          font-size: 18px;
          &.fa-exclamation-triangle{
            margin-top: -3px;
            font-size: 16px;
          }
        }
      }
      .label{
        height: 0px;
        margin-top: 8px;
        white-space: nowrap;
        font-size: 12px;
        color: #AAA;
        white-space: pre-line;
        text-align: center;
      }
    }
    .path{
      height: 6px;
      flex: 1;
      background-color: #DDD;
      border-radius: 3px;
      margin: -6px 0px 0px 0px;
      position: relative;
      // border: 3px solid #D8D8D8;
      .completed{
        height: 100%;
        width: 0%;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 4px;
        background: #3DD892;
        &.progress{
          width: 50%;
        }
        &.done{
          width: 100%;
        }
      }
    }
  }
</style>

<template>
  <div class="milestones">
    <template v-for="(stage, index) in stages" class="milestone">
      <div class="col">
        <div class="milestone" :class="{'active': index <= activeIndex, 'alert': isAlert(stage, index)}">
          <span v-if="isAlert(stage, index)" class="fa fa-exclamation-triangle"></span>
          <span v-else class="fa fa-check"></span>
        </div>
        <div class="label">
          {{stage}}
        </div>
      </div>
      <div class="path" v-if="index < stages.length - 1">
        <div class="completed" :class="{'done': index <= activeIndex - 1}"></div>
      </div>
    </template>
  </div>
</template>

<script>
  import { ReturnStatus } from "@/store/modules/application/status.schema.js"
  export default{
    props: ['stages', 'activeIndex'],
    methods: {
      isAlert(stage, index){
        return [ReturnStatus.awaiting_info, ReturnStatus.awaiting_payment, ReturnStatus.canceled].indexOf(stage) > -1 && index === this.activeIndex
      }
    }
  }
</script>
