import Vue from 'vue'
import { make } from 'vuex-pathify'
import stripe from "@/stripe";

import { updateApplication } from '@/graphql/mutations.js'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { stripeLambda } from '@/graphql/mutations.js'
import { getUniqueID } from "@/lib/id.js"

import { Items } from "@/store/modules/payment/payment.schema.js"

const state = {
  stripe_customer_id: null,
  payments: [],
  pending_items: [],
  purchased_items: [],
  client_secret: null, // DO NOT SAVE!
  auto_saving: false, // Removed functionality from main.js
}

const getters = {
  pending_total: state => {
    return state.pending_items.reduce((acc, item)=>{
      return acc += item.cost
    }, 0);
  },
  stripe_total: (state, getters) => {
    return getters.pending_total * 100
  }
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
}

const actions = {
  loadPayments: ({commit}, current_app)=>{
    commit("payments", current_app.payments ? JSON.parse(current_app.payments) : []);
    commit("pending_items", current_app.pendingItems ? JSON.parse(current_app.pendingItems) : []);
    commit("purchased_items", current_app.purchasedItems ? JSON.parse(current_app.purchasedItems) : []);
    commit("stripe_customer_id", current_app.stripeCustomerId);
    // commit("auto_saving", true)
  },
  updatePayment: ({commit, state, rootState})=>{
    return new Promise((resolve, reject)=>{
      API.graphql(graphqlOperation(updateApplication, {input: {
        id: rootState.application.id,
        updatedAt: new Date(),
        stripeCustomerId: state.stripe_customer_id,
        payments: JSON.stringify(state.payments),
        pendingItems: JSON.stringify(state.pending_items),
        purchasedItems: JSON.stringify(state.purchased_items)
      }})).then(response=>{
        console.log(response);
        resolve(response)
      }).catch(reject)
    })
  },
  createPaymentIntent: ({dispatch, commit}, amount) => {
    return new Promise((resolve, reject)=>{
      dispatch("resolveCustomer").then(customer_id=>{
        API.post('payments', '/payment-intent', {
          body: {
            amount: amount, 
            currency: "usd",
            customer: customer_id,
          }
        }).then(payment_intent=>{
          console.log(payment_intent)
          commit("client_secret", payment_intent.client_secret)
          resolve(payment_intent)
        }).catch((err)=>{
          if(err.response.status == 400){
            if(err.response.data?.msg?.code == "resource_missing" && err.response.data?.msg?.param == "customer"){
              commit("stripe_customer_id", null);
              dispatch("createPaymentIntent", amount)
            }
          }
          console.log(err.response)
          reject(err.response)
        })
      })
    })    
  },
  resolveCustomer: ({dispatch, state}) => {
    return new Promise((resolve, reject)=>{
      if(state.stripe_customer_id){
        resolve(state.stripe_customer_id)
      }else{
        dispatch("createCustomer").then(customer=>{
          console.log(customer);
          resolve(customer.id)
        }).catch(err=>{
          console.log(err);
          reject(err)
        })
      }
    })
  },
  createCustomer: async ({dispatch, commit, state, rootState, rootGetters}) => {
    return new Promise((resolve, reject)=>{
      console.log(rootGetters);
      console.log(rootGetters["application/user"]);
      API.post('payments', '/customer', {
        body: {
          email: rootGetters["auth/email"],
          phone: rootGetters["application/user"].phone,
          name: `${rootGetters["application/user"].first_name} ${rootGetters["application/user"].last_name}`,
          metadata: {
            cognito_id: rootGetters["auth/cognito_id"],
            identity_id: rootState.auth.identity_id,
          }
        }
      }).then(customer=>{
        console.log(customer)
        commit("stripe_customer_id", customer.id)
        dispatch('updatePayment');
        resolve(customer)
      }).catch(err=>{
        console.log(err.response)
        reject(err.response);
      })
    })
  },
  pay: ({dispatch, commit, getters, state, rootState}, card) => {
    return new Promise((resolve, reject)=>{
      stripe.confirmCardPayment(state.client_secret, {
        payment_method: {
          card: card
        }
      }).then(result=>{
        if(result.error){
          reject(result.error.message)
        }else{
          dispatch("checkPaymentIntent").then(response=>{
            var payment = {
              id: getUniqueID(),
              stripe_pi: response.id,
              date: new Date(),
              total: getters.pending_total,
              items: state.pending_items
            }
            commit("payments", [...state.payments, payment])
            commit("purchased_items", [...state.purchased_items, ...state.pending_items])
            commit("pending_items", []);
            dispatch('updatePayment');
            API.post('emailServer', '/email', {
              body: {
                type: "USER_PAYMENT",
                email: rootGetters["auth/email"],
                phone: rootGetters["application/user"].phone,
                name: `${rootGetters["application/user"].first_name} ${rootGetters["application/user"].last_name}`,
                payment: payment,
                cognito_id: rootGetters["auth/cognito_id"],
                identity_id: rootState.auth.identity_id,
              }
            })
          }).catch(err=>{
            reject(err)
          })
        }
      }).catch(err=>{
        reject(err)
      });
    })
  },
  checkPaymentIntent: ({commit, state}) => {
    return new Promise((resolve, reject)=>{
      stripe.retrievePaymentIntent(state.client_secret).then(result=>{
        resolve(result.paymentIntent)
      }).catch(err=>{
        reject(err)
      });
    })
  },
  addItemsToPending: ({commit, state, rootState}) => {
    var items = []
    if(rootState.application.info.had_a_job){
      items.push(Items.w2)
    }
    if(rootState.application.info.dependents && rootState.application.info.dependents.length > 0){
      rootState.application.info.dependents.forEach(dependent=>{
        items.push(Items.dependent)
      })
    }
    if(rootState.application.info.investment_account){
      items.push(Items.stock_account_basic)
    }
    // if(){ // TODO ADD TRADE
    //   items.push(Items.additional_trade)
    // }
    if(rootState.application.info.sole_owner && rootState.application.info.filed_return_for_flow_through_entity){
      items.push(Items.k1_with_states)
    }
    // if(rootState.application.info.sole_owner && rootState.application.info.filed_return_for_flow_through_entity){ // TODO without states
    //   items.push(Items.k1_with_states)
    // }
    // if(){ // TODO ADD sale_of_publicly_traded_partnership
    //   items.push(Items.sale_of_publicly_traded_partnership)
    // }
    if(rootState.application.info.itemize_deductions){
      items.push(Items.itemize_deductions)
    }
    if(rootState.application.info.own_business){
      items.push(Items.small_personal_buisness)
    }
    if(rootState.application.info.own_rental_property){
      items.push(Items.rental_property)
    }
    items = items.filter(item=>{
      return !state.pending_items.some(pi => pi.name === item.name)
    }).filter(item=>{
      return !state.purchased_items.some(pi => pi.name === item.name)
    })
    commit("pending_items", [...state.pending_items, ...items])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
