import Vue from 'vue'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'

import App from './App.vue'
import router from './router'
import store from './store'

import Btn from './components/atoms/Btn.vue'

Vue.config.productionTip = false

Vue.component("btn", Btn);

Vue.directive('click-outside', { 
  inserted: function (el, binding, vnode) {
    setTimeout(()=>{
      el.clickOutsideEvent = event => {
        if(!(el == event.target || el.contains(event.target))){
          binding.value(event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
      document.body.addEventListener("touchstart", el.clickOutsideEvent)
    }, 200)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
    document.body.removeEventListener("touchstart", el.clickOutsideEvent)
  },
});

store.subscribe(mutation => {
  if(mutation.type.indexOf('application') == 0 && mutation.type != "application/loadApplication"){
    console.log(mutation)
    if(mutation.type == 'application/dependents'){
      store.dispatch('application/updateApplicationDependents');
    }else{
      console.log(creatreObjectFromMutation(mutation))
      store.dispatch('application/updateApplication', creatreObjectFromMutation(mutation));
      store.dispatch('storeMutation', mutation);
    }
  }else if (mutation.type.indexOf('files') > -1){
    store.dispatch('storeMutation', mutation);
  }
})

function creatreObjectFromMutation(mutation){
  console.log(mutation)
  const payload = mutation.payload?.path ? {[mutation.payload.path]: mutation.payload.value} : mutation.payload;
  return {[mutation.type.split(/[\/@.]+/)[1]]: payload}
  // return mutation.type.split('/').reverse().reduce((acc, level, index) => (
  //   index == 0 ? {[level]: mutation.payload} : {[level]: acc}
  // ), {})
}

window.onbeforeunload = ()=>{
  if(store.state.updated){
    store.dispatch('sendUpdateEmail');
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
