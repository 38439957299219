<style lang="scss">
  @import "~@/styles/constants.scss";
  @import "~@/styles/general.scss";
  
  $nav_height: 56px;
  
  #app{
    #nav-container{
      width: 100%;
      height: $nav_height;
      position: fixed;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #FFF;
      box-shadow: $z_depth_1;
      z-index: 100;
      .container{
        margin-top: -4px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        &#logo-container{
          transition: transform 0.3s ease;
          &:hover{
            transform: scale(1.03);
          }
          img{
            width: 200px;
          }
        }
        &#page-nav-container{
          .home-nav{
            display: flex;
            align-items: center;
            justify-content: center;
            .nav{
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 24px;
              font-size: 16px;
              font-weight: 600;
              white-space: nowrap;
              &:hover{
                cursor: pointer;
                color: $button;
              }
            }
          }
          .done-area{ 
            color: #FFF;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 50;
          	.button { 
              background-color: $button;
              font-size: 16px;
              height: 36px;
              width: 260px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              box-shadow: $z_depth_1;
              &:hover{
                cursor: pointer;
                background-color: $button-hover;
              }
              &:active{
                background-color: $button-active;
                box-shadow: $z_depth_0;
              }
              &.active{
                box-shadow: $z_depth_0;
              }
              .fa{
                font-size: 16px;
                margin: 0px;
                margin-right: 8px;
              }
              div{
                margin-right: 8px;
              }
          	}
          }
        }
        &#user-container{
          font-weight: 600;
        }
        .link{ 
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 0px 16px;
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
    #view-container{
      box-sizing: border-box;
      min-height: 100%;
      padding-top: $nav_height;
    }
  }
  .fade-page-enter-active{
    transition: all 0.4s;
  }
  .fade-page-leave-active{
    transition: all 0.4s;
  }
  .fade-page-enter{
    opacity: 0;
  }
  .fade-page-leave-to{
    opacity: 0;
  }
</style>

<template>
  <div id='app'>
    <confirmArea id="confirm-area"></confirmArea>
    <div id='nav-container'>
      <div id="logo-container" class="container">
        <div id="nav-logo" class="logo link" @click="$router.push({ name: 'Home' })">
          <img src="./assets/logo-full.svg"  />
        </div>
      </div>
      <div id="page-nav-container" class="container">
        <div class="home-nav" v-if="this.$route.name == 'Home'">
          <div class="nav" @click="goTo('how-it-works')">
            How It Works
          </div>
          <div class="nav" @click="goTo('cost')">
            Cost
          </div>
          <div class="nav" @click="goTo('why-us')">
            Who It’s For
          </div>
          <div class="nav" @click="goTo('row-6')">
            About Us
          </div>
        </div>
        <!-- <div class="done-area update" v-else-if="$store.get('updated') && show_notify_button">
          <div class="button" @click="updateProfile()" v-if="!$store.get('sending_update')">
            <span class="fa fa-inbox-out"></span>Notify CPA of Changes
          </div>
          <div class="button active" v-if="$store.get('sending_update')">
            <spinner-circle></spinner-circle>Notifying CPA
          </div>
        </div> -->
      </div>
      <div id="user-container" class="container">
        <div id="sign-out" class="button link" v-if="signed_in">
          <select-option :options="account_options" @sign-out="$store.dispatch('auth/signOut')" @overview="$router.push({ name: 'Dashboard' })" @my-files="$router.push({ name: 'Files' })" @my-info="$router.push({ name: 'Info' })">
            <btn>{{email}}</btn>
          </select-option>
        </div>
      </div>
    </div>
    <div id="view-container">
      <transition name="fade-page" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
  import SpinnerCircle from '@/components/atoms/SpinnerCircle.vue'
  import SelectOption from '@/components/molecules/SelectOption.vue'
  import ConfirmArea from '@/components/organisms/ConfirmArea.vue';
  import { ApplicationStatus, ApplicationStatusFlow } from "@/store/modules/application/status.schema.js"

  export default {
    name: 'app',
    components: { SpinnerCircle, SelectOption, ConfirmArea },
    data() {
      return {
        
      }
    },
    computed: {
      signed_in(){
        return this.$store.get('auth/signed_in');
      },
      email(){
        return this.$store.get('auth/user').email;
      },
      account_options(){
        if(this.signed_in){
          return ['Overview', 'My Info', 'My Files', '', 'Sign Out']
        }else{
          
        }
      },
      show_notify_button(){
        return ![
          "GetStarted",
          "Matching",
          "GetUploading",
          "Payment",
        ].includes(this.$route.name)
      }
    },
    methods: {
      goTo(elm_id){
        if(this.$route.name != "Home"){
          this.$router.push({ name: 'Home' })
        }else{
          window.scrollTo({top: document.getElementById(elm_id).getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth'});
        }
      },
      updateProfile(){
        this.$store.dispatch("sendUpdateEmail").then(response=>{
          this.$store.commit('confirm', {
            title: "Success!",
            description: "Your CPA has been notified about the changes you've made.",
            start_label: "Continue",
            stop_label: "NONE",
            start: ()=>{},
            stop: ()=>{}
          });
        }).catch(err=>{
          console.log(err);
          this.$store.commit('confirm', {
            title: "Oh No! Something went wrong.",
            description: "We are unable to send your CPA an update at this time. Please reach out to your CPA directly.",
            start_label: "Continue",
            stop_label: "NONE",
            start: ()=>{},
            stop: ()=>{}
          });
        })
      },
    }
  }
</script>
