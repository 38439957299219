/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const stripeLambda = /* GraphQL */ `
  mutation StripeLambda($path: String, $data: String) {
    stripeLambda(path: $path, data: $data)
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      email
      identityId
      updatedAt
      year
      userInfo
      status
      cpa
      stripeCustomerId
      payments
      pendingItems
      purchasedItems
      owner
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      email
      identityId
      updatedAt
      year
      userInfo
      status
      cpa
      stripeCustomerId
      payments
      pendingItems
      purchasedItems
      owner
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      email
      identityId
      updatedAt
      year
      userInfo
      status
      cpa
      stripeCustomerId
      payments
      pendingItems
      purchasedItems
      owner
    }
  }
`;
