<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  #payment-page{
    width: 100%;
    min-height: calc(100vh - 56px);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // background: rgb(242,105,142);
    // background: linear-gradient(155deg, rgba(242,105,142,1) 0%, rgba(170,65,226,1) 100%);
    .limiter{
      width: 500px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-bottom: 100px;
      .back{
        margin-top: 32px;
        margin-bottom: 8px;
        display: inline-flex;
      }
      .card{
        width: 100%;
      }
    }
  }
</style>

<template>
  <div id="payment-page">
    <div class="limiter">
      <btn type="back" class="back" @click="$router.push({ name: 'GetUploading' })"><span class="fa fa-arrow-left"></span>Back</btn>
      <pay></pay>
    </div>
  </div>
</template>

<script>
  import Pay from "@/components/organisms/Pay.vue";
  
  export default{
    components: { Pay }
  }
</script>
