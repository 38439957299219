<style lang="scss" scoped>
  .loading-bar{
    position: fixed;
    top: 56px;
    left: 0px;
    width: 100%;
    height: 8px;
    background-color: #DDD;
    transition: opacity 0.4s ease;
    &.done{
      opacity: 0;
    }
    .progress{
      height: 100%;
      width: 0%;
      position: absolute;
      top: 0px;
      left: 0px;
      background: #3DD892;
      transition: width 0.2s ease;
      &.yellow{
        background: #edc32b;
      }
    }
  }
</style>

<template>
	<div class="loading-bar" :class="{done}">
    <div class="progress" :style="{ width: progress+'%' }" :class="color"></div>
  </div>
</template>

<script>
  export default {
    props: [ "progress", "error", "color" ],
    computed: {
      done(){
        return !this.progress || this.progress >= 100 || this.progress <= 0;
      }
    }
  }
</script>
