import Vue from 'vue'
import Vuex from 'vuex'
import Pathify from './pathify.js'

import Files from './modules/files';
import Application from './modules/application';
import Auth from './modules/auth';
import Payment from './modules/payment';

import { API } from 'aws-amplify'

Vue.use(Vuex)

const state = {
  confirmations: [],
  updated: localStorage.getItem("Updated") ? JSON.parse(localStorage.getItem("Updated")) : false,
  mutation_types: localStorage.getItem("Mutation_Types") ? JSON.parse(localStorage.getItem("Mutation_Types")) : {},
  sending_update: false,
}

const mutations = {
  confirm(state, alert){
    state.confirmations.push(alert);
  },
  resolve(state){
    state.confirmations.shift();
  },
  clearMutationTypes(state){
    state.mutation_types = {};
    localStorage.setItem("Mutation_Types", JSON.stringify(state.mutation_types));
  },
  addMutationType(state, type){
    Object.assign(state.mutation_types, type);
    localStorage.setItem("Mutation_Types", JSON.stringify(state.mutation_types));
  },
  setUpdated(state, value){
    localStorage.setItem("Updated", JSON.stringify(value));
    state.updated = value;
  },
  setSendingUpdates(state, value){
    state.sending_update = value;
  }
}

const actions = {
  storeMutation: ({dispatch, commit, state}, mutation)=>{
    try{
      if(mutation.type.indexOf('files') > -1){
        if(mutation.payload.uploading){
          dispatch("addMutation", {["File-"+mutation.payload.id.replace("files/", "")]: new Date()})
        }
      }else if(mutation.type == "application/updateDependent"){
        Object.keys(mutation.payload.dependent).map(sub_key=>{
          dispatch("addMutation", {["Dependent-"+mutation.payload.index+"-"+sub_key]: mutation.payload.dependent[sub_key]})
        })
      }else if(typeof mutation.payload == "string"){
        dispatch("addMutation", {[mutation.type.replace("application/update", "")]: mutation.payload})
      }else{
        Object.keys(mutation.payload).map(key=>{
          dispatch("addMutation", {[mutation.type.replace("application/update", "")+"-"+key]: mutation.payload[key]})
        })
      }
    }catch(e){
      console.log(e);
    }
  },
  addMutation: ({commit}, type)=>{
    commit("setUpdated", true);
    commit("addMutationType", type);
  },
  sendUpdateEmail: ({commit, state, rootState, rootGetters})=>{
    return new Promise((resolve, reject)=>{
      commit("setSendingUpdates", true);
      API.post('emailServer', '/email', {
        body: {
          type: "USER_UPDATE",
          email: state.application.email,
          phone: rootGetters["application/user"].phone,
          cognito_id: rootGetters["auth/cognito_id"],
          identity_id: rootState.auth.identity_id,  
          updates: state.mutation_types
        }
      }).then(response=>{
        commit("setSendingUpdates", false);
        commit("clearMutationTypes");
        commit("setUpdated", false)
        resolve(response);
      }).catch(err=>{
        commit("setSendingUpdates", false);
        reject(err)
      })
    })
  }
}

export default new Vuex.Store({
  plugins: [ Pathify.plugin ],
  modules: {
    files: Files,
    application: Application,
    auth: Auth,
    payment: Payment,
  },
  state,
  mutations,
  actions,
})
