import Vue from 'vue'
import { make } from 'vuex-pathify'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { listApplications } from '@/graphql/queries.js'
import { createApplication, updateApplication } from '@/graphql/mutations.js'
import { newApplication,  newUser, newDependentUser, newAddress } from "./info.schema.js"
import { ApplicationStatus, ApplicationStatusFlow, ReturnStatus } from "@/store/modules/application/status.schema.js"
import router from '@/router'
import * as firebase from '@/firebase.js'

const state = {
  ...newApplication(),
}

const getters = {
  ...make.getters([...Object.keys(state)]),
}

const mutations = {
  ...make.mutations([...Object.keys(state)]),
  // personal_return: (state, value) => {
  //   if(!state.personal_return_status && value){
  //     state.personal_return_status = ApplicationStatus.requested
  //   }else if(state.personal_return_status === ApplicationStatus.requested && !value){
  //     state.personal_return_status = ApplicationStatus.none
  //   }
  //   state.personal_return = value
  // },
  // business_return: (state, value) => {
  //   if(!state.business_return_status && value){
  //     state.business_return_status = ApplicationStatus.requested
  //   }else if(state.business_return_status === ApplicationStatus.requested && !value){
  //     state.business_return_status = ApplicationStatus.none
  //   }
  //   state.business_return = value
  // },
  // other_return: (state, value) => {
  //   console.log("CALLED")
  //   console.log(value)
  //   console.log(state.other_return_status)
  //   if(!state.other_return_status && value){
  //     console.log("HIT")
  //     state.other_return_status = ApplicationStatus.requested
  //   }else if(state.other_return_status === ApplicationStatus.requested && !value){
  //     state.other_return_status = ApplicationStatus.none
  //   }
  //   state.other_return = value
  //   console.log(state)
  // },
  loadApplication: (state, value) => {
    Object.assign(state, value)
  },
  status: (state, value) => {
    if(!state.status || ApplicationStatusFlow[value] > ApplicationStatusFlow[state.status]){
      console.log(ApplicationStatusFlow[value]);
      console.log(ApplicationStatusFlow[state.status]);
      state.status = value
    }
  },
  // updateUser: (state, obj) => {
  //   state.user = Object.assign({}, state.user, obj)
  // },
  // updateHomeAddress: (state, obj) => {
  //   state.home_address = Object.assign({}, state.home_address, obj)
  // },
  // updateBusinessAddress: (state, obj) => {
  //   state.business_address = Object.assign({}, state.business_address, obj)
  // },
  // updateSpouse: (state, obj) => {
  //   state.spouse = Object.assign({}, state.spouse, obj)
  // },
  // updateDependent: (state, {index, dependent}) => {
  //   Vue.set(state.dependents, index, Object.assign({}, state.dependents[index], dependent))
  // },
  // updatePayingAlimonyTo: (state, user) => {
  //   state.paying_alimony_to = Object.assign({}, state.paying_alimony_to, user)
  // },
  // updateReceivingAlimonyFrom: (state, user) => {
  //   state.receiving_alimony_from = Object.assign({}, state.receiving_alimony_from, user)
  // },
  // addDependent: (state) => {
  //   state.dependents = [...state.dependents, newDependentUser()]
  // },
  // removeDependent: (state, index) => {
  //   state.dependents.splice(index, 1)
  // }
}

const actions = {
  updateStatus: ({commit, dispatch, rootState}, {path, value}) => {
    const status_path = `${path}_status`;
    commit(path, value)
    if(!state[status_path] && value){
      commit(status_path, ReturnStatus.requested)
    }else if(state[status_path] === ReturnStatus.requested && !value){
      commit(status_path, ReturnStatus.none)
    }else if(state[status_path] && !value){
      commit(status_path, ReturnStatus.canceled)
    }else if(state[status_path] === ReturnStatus.canceled && value){
      commit(status_path, ReturnStatus.requested)
    }
  },
  loadApplication: ({commit, dispatch, rootState}) => {
    return new Promise((resolve, reject)=>{
      firebase.db.collection("applications").where("userId", "==", rootState.auth.user.uid).get().then((querySnapshot) => {
        console.log(querySnapshot)
        var current_app = null;
        querySnapshot.forEach((doc)=> {
          console.log(doc.data())
          if(doc.data().year === new Date().getFullYear()){
            current_app = doc.data()
          }
        });
        if(current_app){
          console.log("Loading Application")
          commit("loadApplication", current_app)
          resolve()
        }else{
          console.log("Creating Application")
          dispatch("createApplication")
          resolve()
        }
      });
    }).catch(e => {
      console.log(e)
      reject(e)
    })
  },
  createApplication: ({dispatch, commit, state, rootState, rootGetters}) => {
    return new Promise((resolve, reject)=>{
      firebase.db.collection("applications").doc(state.id).set({
        ...state,
        userId: rootState.auth.user.uid,
        email: rootState.auth.user.email,
      })
    })
  },
  updateApplication: ({commit, state, rootState}, obj) => {
    return new Promise((resolve, reject)=>{
      firebase.db.collection("applications").doc(state.id).set({
        ...obj
      }, { merge: true })
    })
  },
  updateApplicationDependents: ({commit, state, rootState}) => {
    return new Promise((resolve, reject)=>{
      firebase.db.collection("applications").doc(state.id).set({
        dependents: state.dependents
      }, { merge: true })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
