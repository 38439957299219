<style lang="scss" scoped>
  @import "~@/styles/constants";
  .button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 36px;
    padding: 0px 16px;
    color: $button;
    border-radius: 3px;
    white-space: nowrap;
    position: relative;
    &:hover{
      cursor: pointer;
      background-color: $button-light-hover;
    }
    &:active{
      background-color: $button-light-active;
    }
    &.full{
      display: flex;
    }
    &.small{
      min-height: 15px;
      padding: 4px 12px;
      font-size: 12px;
      &.link{
        min-height: 8px;
        padding: 1px 2px;
      }
    }
    &.fill{
      background-color: $button;
      color: $w;
      box-shadow: $z_depth_1;
      &:hover{
        background-color: $button-hover;
      }
      &:active{
        background-color: $button-active;
        box-shadow: $z_depth_0;
      }
    }
    &.outline{
      border: 1px solid $button;
    }
    &.back{
      color: #FFF;
      &:hover{
        background-color: rgba(0,0,0,0.1)
      }
    }
    .fa{
      margin-right: 8px;
    }
    span{
      &:not(:first-child){
        margin-left: 6px;
      }
      &.fa-ellipsis-h{
        transform: scale(1.8);
      }
      &.fa-pen{
        // font-size: 10px;
      }
    }
    // .fa, .fal, .far{
    //   margin-right: 6px;
    // }
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20rem;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      border-bottom: 0px solid #FFF;
    }
  }
</style>

<template>
  <div class="button" :class="[type, size, stretch]" @click="$emit('click', $event)">
    <slot></slot>
  </div>
</template>

<script>
  export default{
    props: [ 
      'type', // 'fill', 'outline',
      'size',  // 'small'
      'stretch', // 'full'
    ],
  }
</script>
