<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .questions{
    .card{
      width: 100%;
      padding: 64px 100px;
      display: flex;
      margin-bottom: 64px;
      .question{
        flex: 1;
        margin-right: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .header{
          font-size: 20px;
          font-weight: 600;
        }
        .description{
          margin-top: 16px;
          font-size: 18px;
          font-weight: 500;
        }
      }
      .answers{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        &.notes{
          flex-basis: 20%;
        }
        .address{
          width: 100%;
          .row{
            display: flex;
            align-items: center;
            .address{
              flex: 1;
              margin-right: 16px;
            }
            .apt{
              width: 100px;
            }
            .zip{
              width: 100px;
              margin-right: 16px;
            }
            .city{
              flex: 1;
              margin-right: 16px;
            }
            .state{
              width: 80px;
            }
          }
        }
        .form-input{
          margin-bottom: 16px;
        }
        .area{
          margin-top: 32px;
        }
        .area-title{
          font-size: 20px;
          margin: 16px 0px 16px 0px;
        }
        .item{
          // border: 1px solid #CCC;
          // padding: 32px;
          margin: 40px 0px;
          .child-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            .name{
              font-size: 16px;
              // font-weight: 600;
            }
          }
        }
      }
    }
    .split{
      display: flex;
      div{
        flex: 1;
        min-width: 80px;
        &:not(:first-child){
          margin-left: 16px;
        }
      }
    }
    .done-area { 
      color: #FFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    	.fa { 
        font-size: 40px;
        margin-bottom: 16px;
    	}
    	.title { 
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 16px;
    	}
    	.message { 
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 32px;
    	}
    	.button { 
        font-size: 20px;
        font-weight: 500;
        background-color: #F5B132;
        height: 56px;
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        box-shadow: $z_depth_1;
        &:hover{
          cursor: pointer;
          background-color: #e3a32b;
        }
        &:active{
          background-color: #d69927;
          box-shadow: $z_depth_0;
        }
    	}
    }
  }
  
  .fade-card-enter-active{
    transition: all 0.6s;
  }
  .fade-card-leave-active{
    transition: all 0.4s;
  }
  .fade-card-enter{
    opacity: 0;
  }
  .fade-card-leave-to{
    opacity: 0;
  }
</style>

<template>
  <div class="questions">
    <div class="card">
      <div class="question">
        <div class="header">
          What can we assist you with?
        </div>
      </div>
      <div class="answers">
        <check-input title="Personal tax return" :value="$store.get('application/personal_return')" @update:value="$store.dispatch('application/updateStatus', {path: 'personal_return', value: $event})"></check-input>
        <check-input title="Business tax return" :value="$store.get('application/business_return')" @update:value="$store.dispatch('application/updateStatus', {path: 'business_return', value: $event})"></check-input>
        <check-input title="Other" :value="$store.get('application/other_return')" @update:value="$store.dispatch('application/updateStatus', {path: 'other_return', value: $event})"></check-input>
        <area-input title="What do you need help with?" v-if="$store.get('application/other_return')" :value="$store.get('application/other_return_description')" @update:value="$store.set('application/other_return_description', $event)"></area-input>
      </div>
    </div>
    <transition name="fade-card">
      <div class="card" v-show="stage > 0">
        <div class="question">
          <div class="header">
            First, we need to confirm some information about you.
          </div>
          <div class="description">
            Dont worry, we promise it wont take long.
          </div>
        </div>
        <div class="answers">
          <form-input class="first" title="First Name" :value="$store.get('application/first_name')" @update:value="$store.set('application/first_name', $event)"></form-input>
          <form-input class="last" title="Last Name" :value="$store.get('application/last_name')" @update:value="$store.set('application/last_name', $event)"></form-input>
          <form-input title="Phone #" :value="$store.get('application/phone_number')" @update:value="$store.set('application/phone_number', $event)"></form-input>
          <div class="split" v-if="$store.get('application/personal_return')">
            <date-input title="Birthday" :value="$store.get('application/dob')" @update:value="$store.set('application/dob', $event)"></date-input>
            <form-input title="SSN" icon="fa-lock" :value="$store.get('application/ssn')" @update:value="$store.set('application/ssn', $event)"></form-input>
          </div>
          <check-input v-if="$store.get('application/personal_return')" title="Someone else can claim you as a dependent" :value="$store.get('application/dependent_user')" @update:value="$store.set('application/dependent_user', $event)"></check-input>
        </div>
      </div>
    </transition>
    <div v-if="$store.get('application/business_return')">
      <transition name="fade-card">
        <div class="card" v-show="stage > 1">
          <div class="question">
            <div class="header">
              Tells us about your business
            </div>
          </div>
          <div class="answers">
            <form-input title="Name of Business" :value="$store.get('application/business_name')" @update:value="$store.set('application/business_name', $event)"></form-input>
            <form-input title="EIN" :value="$store.get('application/ein')" @update:value="$store.set('application/ein', $event)"></form-input>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="stage > 2">
          <div class="question">
            <div class="header">
              Where is your business located?
            </div>
          </div>
          <div class="answers">
            <div class="address">
              <div class="row">
                <form-input class="address" title="Address" :value="$store.get('application/business_address@street')" @update:value="$store.set('application/business_address@street', $event)"></form-input>
                <form-input class="apt" title="Apt" :value="$store.get('application/business_address@apt')" @update:value="$store.set('application/business_address@apt', $event)"></form-input>
              </div>
              <div class="row">
                <form-input class="zip" title="Zip Code" :value="$store.get('application/business_address@zip_code')" @update:value="$store.set('application/business_address@zip_code', $event)"></form-input>
                <form-input class="city" title="City" :value="$store.get('application/business_address@city')" @update:value="$store.set('application/business_address@city', $event)"></form-input>
                <form-input class="state" title="State" :value="$store.get('application/business_address@state')" @update:value="$store.set('application/business_address@state', $event)"></form-input>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="$store.get('application/personal_return')">
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 3 : stage > 1">
          <div class="question">
            <div class="header">
              Where is your primary address?
            </div>
          </div>
          <div class="answers">
            <div class="address">
              <div class="row">
                <form-input class="address" title="Address" :value="$store.get('application/home_address@street')" @update:value="$store.set('application/home_address@street', $event)"></form-input>
                <form-input class="apt" title="Apt" :value="$store.get('application/home_address@apt')" @update:value="$store.set('application/home_address@apt', $event)"></form-input>
              </div>
              <div class="row">
                <form-input class="zip" title="Zip Code" :value="$store.get('application/home_address@zip_code')" @update:value="$store.set('application/home_address@zip_code', $event)"></form-input>
                <form-input class="city" title="City" :value="$store.get('application/home_address@city')" @update:value="$store.set('application/home_address@city', $event)"></form-input>
                <form-input class="state" title="State" :value="$store.get('application/home_address@state')" @update:value="$store.set('application/home_address@state', $event)"></form-input>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 4 : stage > 2">
          <div class="question">
            <div class="header">
              What is your marital status?
            </div>
          </div>
          <div class="answers">
            <select-input title="Marital Status" :value="$store.get('application/marital_status')" :options="RelationshipStatus" @update:value="$store.set('application/marital_status', $event)"></select-input>
            <check-input title="Your martital status changed this year" :value="$store.get('application/marital_status_change_this_year')" @update:value="$store.set('application/marital_status_change_this_year', $event)"></check-input>
            <div v-if="$store.get('application/marital_status') == RelationshipStatus.married">
              <check-input title="You plan on filing jointly" :value="$store.get('application/filing_jointly')" @update:value="$store.set('application/filing_jointly', $event)"></check-input>
              <div class="area">
                <div class="area-title">
                  Your Spouse
                </div>
                <div class="split">
                  <form-input class="first" title="First Name" :value="$store.get('application/spouse@first_name')" @update:value="$store.set('application/spouse@first_name', $event)"></form-input>
                  <form-input class="last" title="Last Name" :value="$store.get('application/spouse@last_name')" @update:value="$store.set('application/spouse@last_name', $event)"></form-input>
                </div>
                <div class="split">
                  <date-input title="Birthday" :value="$store.get('application/spouse@dob')" @update:value="$store.set('application/spouse@dob', $event)"></date-input>
                  <form-input title="SSN" icon="fa-lock" :value="$store.get('application/spouse@ssn')" @update:value="$store.set('application/spouse@ssn', $event)"></form-input>
                </div>
                <check-input title="Someone else can claim your spouse as a dependent" :value="$store.get('application/dependent_spouse')" @update:value="$store.set('application/dependent_spouse', $event)"></check-input>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 5 : stage > 3">
          <div class="question">
            <div class="header">
              Do you have children?
            </div>
          </div>
          <div class="answers">
            <div class="">
              <div class="area-title">
                Dependent Children
              </div>
              <transition-group name="fade">
                <div v-for="(dependent, index) in $store.get('application/dependents')" :key="index" class="item">
                  <div class="child-header">
                    <div class="name">
                      {{stringifyNumber(index+1)}} Child
                    </div>
                    <btn size="small" @click="removeDependent(index)">Remove Child</btn>
                  </div>
                  <div class="split">
                    <form-input class="first" title="First Name" :value="dependent.first_name" @update:value="$store.set('application/dependents@['+index+'].first_name', $event)"></form-input>
                    <form-input class="last" title="Last Name" :value="dependent.last_name" @update:value="$store.set('application/dependents@['+index+'].last_name', $event)"></form-input>
                  </div>
                  <div class="split">
                    <date-input title="Birthday" :value="dependent.dob" @update:value="$store.set('application/dependents@['+index+'].dob', $event)"></date-input>
                    <form-input title="SSN" icon="fa-lock" :value="dependent.ssn" @update:value="$store.set('application/dependents@['+index+'].ssn', $event)"></form-input>
                  </div>
                  <form-input title="Relationship" :value="dependent.relationship" @update:value="$store.set('application/dependents@['+index+'].relationship', $event)"></form-input>
                  <form-input title="Months Living Togeather" :value="dependent.months_living_togeather" @update:value="$store.set('application/dependents@['+index+'].months_living_togeather', $event)"></form-input>
                  <check-input title="Received child care" :value="dependent.child_care" @update:value="$store.set('application/dependents@['+index+'].child_care', $event)"></check-input>
                  <check-input title="Current Student" :value="dependent.student" @update:value="$store.set('application/dependents@['+index+'].student', $event)"></check-input>
                  <check-input title="They were permantly or totally disabled at any point during this year?" :value="dependent.disabled" @update:value="$store.set('application/dependents@['+index+'].disabled', $event)"></check-input>
                </div>
              </transition-group>
              <btn type="fill" @click="addDependent()">Add Child</btn>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 6 : stage > 4">
          <div class="question">
            <div class="header">
              Tell us about your work.
            </div>
          </div>
          <div class="answers">
            <check-input title="You had a job" :value="$store.get('application/had_a_job')" @update:value="$store.set('application/had_a_job', $event)"></check-input>
            <check-input title="You worked as a contract employee" :value="$store.get('application/contract_employee')" @update:value="$store.set('application/contract_employee', $event)"></check-input>
            <check-input title="You owned your own business" :value="$store.get('application/own_business')" @update:value="$store.set('application/own_business', $event)"></check-input>
            <check-input title="You are the sole owner of your business" v-if="$store.get('application/own_business')" :value="$store.get('application/sole_owner')" @update:value="$store.set('application/sole_owner', $event)"></check-input>
            <check-input title="You filed a business tax return (1065, 1120S) for flow through entity" v-if="$store.get('application/sole_owner')" :value="$store.get('application/filed_return_for_flow_through_entity')" @update:value="$store.set('application/filed_return_for_flow_through_entity', $event)"></check-input>
            <check-input title="You owned a farm" :value="$store.get('application/owned_a_farm')" @update:value="$store.set('application/owned_a_farm', $event)"></check-input>
            <check-input title="You plan to itemize your deductions" :value="$store.get('application/itemize_deductions')" @update:value="$store.set('application/itemize_deductions', $event)"></check-input>
            <form-input title="Property tax amount" v-if="$store.get('application/itemize_deductions')" :value="$store.get('application/property_tax_amount')" @update:value="$store.set('application/property_tax_amount', $event)"></form-input>
            <check-input title="You had a household employee that you paid employment tax on" :value="$store.get('application/paid_employment_tax_on_household_employee')" @update:value="$store.set('application/paid_employment_tax_on_household_employee', $event)"></check-input>
            <form-input title="Amount of employment taxes you payed" v-if="$store.get('application/paid_employment_tax_on_household_employee')" :value="$store.get('application/amount_of_employment_taxes')" @update:value="$store.set('application/amount_of_employment_taxes', $event)"></form-input>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 7 : stage > 5">
          <div class="question">
            <div class="header">
              Tell us about your financials and investments.
            </div>
          </div>
          <div class="answers">
            <check-input title="You bought or sold a house this year" :value="$store.get('application/bought_sold_house')" @update:value="$store.set('application/bought_sold_house', $event)"></check-input>
            <check-input title="You have an investment account" :value="$store.get('application/investment_account')" @update:value="$store.set('application/investment_account', $event)"></check-input>
            <check-input title="You traded cryptocurrency this year" :value="$store.get('application/traded_cryptocurrency')" @update:value="$store.set('application/traded_cryptocurrency', $event)"></check-input>
            <check-input title="You have a foregin bank account" :value="$store.get('application/foregin_bank_account')" @update:value="$store.set('application/foregin_bank_account', $event)"></check-input>
            <check-input title="You received interest on a bank account" :value="$store.get('application/interest_on_a_bank_account')" @update:value="$store.set('application/interest_on_a_bank_account', $event)"></check-input>
            <check-input title="You received social security" :value="$store.get('application/received_social_security')" @update:value="$store.set('application/received_social_security', $event)"></check-input>
            <check-input title="You received retirement account distributions" :value="$store.get('application/received_retirement_account_distributions')" @update:value="$store.set('application/received_retirement_account_distributions', $event)"></check-input>
            <check-input title="You owned a rental property" :value="$store.get('application/own_rental_property')" @update:value="$store.set('application/own_rental_property', $event)"></check-input>
            <check-input title="You receive royalty payments" :value="$store.get('application/receive_royalty_payments')" @update:value="$store.set('application/receive_royalty_payments', $event)"></check-input>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 8 : stage > 6">
          <div class="question">
            <div class="header">
              Tell us about any academic expenses.
            </div>
          </div>
          <div class="answers">
            <check-input title="You paid tuition this year" :value="$store.get('application/paid_tuition')" @update:value="$store.set('application/paid_tuition', $event)"></check-input>
            <check-input title="You paid student loan interest" :value="$store.get('application/paid_student_loan_interest')" @update:value="$store.set('application/paid_student_loan_interest', $event)"></check-input>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 9 : stage > 7">
          <div class="question">
            <div class="header">
              Former spouse payments
            </div>
          </div>
          <div class="answers">
            <check-input title="You are paying alimony" :value="$store.get('application/paying_alimony')" @update:value="$store.set('application/paying_alimony', $event)"></check-input>
            <div v-if="$store.get('application/paying_alimony')">
              <form-input title="Alimony paid" :value="$store.get('application/alimony_paid')" @update:value="$store.set('application/alimony_paid', $event)"></form-input>
              <div class="split">
                <form-input title="Ex-Spouses First Name" :value="$store.get('application/paying_alimony_to@first_name')" @update:value="$store.set('application/paying_alimony_to@first_name', $event)"></form-input>
                <form-input title="Ex-Spouses Last Name" :value="$store.get('application/paying_alimony_to@last_name')" @update:value="$store.set('application/paying_alimony_to@last_name', $event)"></form-input>
              </div>
              <form-input title="Ex-Spouses SSN" icon="fa-lock" :value="$store.get('application/paying_alimony_to@ssn')" @update:value="$store.set('application/paying_alimony_to@ssn', $event)"></form-input>
            </div>
            <check-input title="You are receiving alimony payments" :value="$store.get('application/receiving_alimony')" @update:value="$store.set('application/receiving_alimony', $event)"></check-input>
            <div v-if="$store.get('application/receiving_alimony')">
              <form-input title="Alimony Received" :value="$store.get('application/alimony_received')" @update:value="$store.set('application/alimony_received', $event)"></form-input>
              <div class="split">
                <form-input title="Ex-Spouses First Name" :value="$store.get('application/receiving_alimony_from@first_name')" @update:value="$store.set('application/receiving_alimony_from@first_name', $event)"></form-input>
                <form-input title="Ex-Spouses Last Name" :value="$store.get('application/receiving_alimony_from@last_name')" @update:value="$store.set('application/receiving_alimony_from@last_name', $event)"></form-input>
              </div>
              <form-input title="Ex-Spouses SSN" icon="fa-lock" :value="$store.get('application/receiving_alimony_from@ssn')" @update:value="$store.set('application/receiving_alimony_from@ssn', $event)"></form-input>
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade-card">
        <div class="card" v-show="$store.get('application/business_return') ? stage > 10 : stage > 8">
          <div class="question">
            <div class="header">
              How would you like to receive you refund?
            </div>
          </div>
          <div class="answers">
            <check-input title="Send me a check" :value="!$store.get('application/request_refund_direct_deposit')" @update:value="$store.set('application/request_refund_direct_deposit', false)"></check-input>
            <check-input title="Deposite directly into my bank account?" :value="$store.get('application/request_refund_direct_deposit')" @update:value="$store.set('application/request_refund_direct_deposit', true)"></check-input>
          </div>
        </div>
      </transition>
    </div>
    <transition name="fade-card">
      <div class="card" v-show="$store.get('application/business_return') ? stage > 11 : stage > 9">
        <div class="question">
          <div class="header">
            Anything else you would like to mention?
          </div>
        </div>
        <div class="answers notes">
          <area-input title="Notes" :value="$store.get('application/additional_info')" @update:value="$store.set('application/additional_info', $event)"></area-input>
        </div>
      </div>
    </transition>
    <transition name="fade-card">
      <div class="done-area" v-show="$store.get('application/business_return') ? stage > 12 : stage > 10" v-if="!update">
        <!-- <span class="fa fa-thumbs-up"></span>
        <div class="title">
          Thank You
        </div> -->
        <div class="message">
          Please verify that your information is correct<br />
          then press submit.
        </div>
        <div class="button" @click="finishedProfile()">
          Submit
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import DropDownArea from "@/components/molecules/DropDownArea.vue"
  import CheckInput from "@/components/molecules/CheckInput.vue"
  import DateInput from "@/components/molecules/DateInput.vue"
  import FormInput from "@/components/molecules/FormInput.vue"
  import AreaInput from "@/components/molecules/AreaInput.vue"
  import SelectInput from "@/components/molecules/SelectInput.vue"
  import AddressInput from "@/components/organisms/AddressInput.vue"
  import NameInput from "@/components/organisms/NameInput.vue"
  import LoadingBar from "@/components/atoms/LoadingBar.vue"
  
  import { ApplicationStatus } from "@/store/modules/application/status.schema.js"
  import { RelationshipStatus, newDependentUser } from "@/store/modules/application/info.schema.js"
  
  const stage_count = 12;
  const special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
  const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];
  
  export default{
    components: { DropDownArea, CheckInput, DateInput, FormInput, AreaInput, SelectInput, AddressInput, NameInput, LoadingBar },
    props: ['update'],
    mounted(){
      this.scrollCards()
      window.addEventListener('scroll', event=>{
        this.scrollCards()
      });
    },
    data(){
      return{
        RelationshipStatus,
        stage: this.update ? stage_count : 0,
      }
    },
    computed: {
      progress(){
        return this.stage * (100 / stage_count)
      }
    },
    methods: {
      addDependent(){
        this.$store.set('application/dependents', [...this.$store.get('application/dependents'), newDependentUser()])
      },
      removeDependent(index){
        this.$store.set('application/dependents', this.$store.get('application/dependents').filter((val, i) => i !== index))
      },
      stringifyNumber(n){
        var s = this.convertNumber(n);
        return s.charAt(0).toUpperCase() + s.slice(1);
      },
      convertNumber(n){
        if (n < 20) return special[n];
        if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
        return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
      },
      finishedProfile(){
        this.$store.commit("application/status", ApplicationStatus.profile_completed);
        this.$store.dispatch("sendUpdateEmail");
        this.$router.push({ name: 'GetUploading' });
      },
      scrollCards(){
        var win_h = (self.innerHeight) ? self.innerHeight : document.body.clientHeight;    // gets window height
        var scrl_pos = window.pageYOffset ? window.pageYOffset : document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
        if(document.body.scrollHeight <= (scrl_pos + win_h)){
          if(this.stage <= stage_count){
            this.stage ++;
          }
        }
      }
    }
  }
</script>
