<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .frame{
    width: 100%;
    height: calc(100vh - 56px);
  }
</style>

<template>
  <div class="frame">
    <iframe src="https://secure.na2.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAdU5Ju4vqHkCSBY9pUiz1d_z8PQIy6Y2kVtfyPbZSlHUudt9DPzzD4nDEMxg9KcVU*&hosted=false" width="100%" height="100%" frameborder="0" style="border: 0; overflow: hidden; min-height: 500px; min-width: 600px;"></iframe>
  </div>
</template>

<script>
  import stripe from "@/stripe";
  import SpinnerCircle from "@/components/atoms/SpinnerCircle.vue"

  export default{
    components: { SpinnerCircle },
    mounted(){
      window.addEventListener('message', (val)=>{
        console.log(val);
      });
    },
    data(){
      
    },
    computed:{
      
    },
    watch: {
      
    },
    methods: {
      
    }
  }
</script>
