<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  .container-area{
    .file-container{
      margin-bottom: 64px;
    }
  }
</style>

<template>
  <div class="container-area">
    
    <file-container title="Prior Year's Tax Return" :files="fetchFiles(FileBucket.prior_return)" @upload="files => uploadFiles(files, FileBucket.prior_return)"></file-container>
    
    <file-container title="Spouse's Prior Year Tax Return" v-if="info.marital_status_change_this_year || fetchFiles(FileBucket.spouse_prior_return).length > 0" :files="fetchFiles(FileBucket.spouse_prior_return)" @upload="files => uploadFiles(files, FileBucket.spouse_prior_return)"></file-container>
    
    <file-container title="Divers License" :files="fetchFiles(FileBucket.divers_license)" @upload="files => uploadFiles(files, FileBucket.divers_license)"></file-container>
    
    <file-container title="W2s" v-if="info.had_a_job || fetchFiles(FileBucket.w2).length > 0" :files="fetchFiles(FileBucket.w2)" @upload="files => uploadFiles(files, FileBucket.w2)"></file-container>
    
    <file-container title="Investment Account 1099" v-if="info.investment_account || fetchFiles(FileBucket.investment_account_1099).length > 0" :files="fetchFiles(FileBucket.investment_account_1099)" @upload="files => uploadFiles(files, FileBucket.investment_account_1099)"></file-container>
    
    <file-container title="Bank Account 1099" v-if="info.interest_on_a_bank_account || fetchFiles(FileBucket.bank_account_1099).length > 0" :files="fetchFiles(FileBucket.bank_account_1099)" @upload="files => uploadFiles(files, FileBucket.bank_account_1099)"></file-container>
    
    <file-container title="Contractor 1099" v-if="info.contract_employee || fetchFiles(FileBucket.contract_1099).length > 0" :files="fetchFiles(FileBucket.contract_1099)" @upload="files => uploadFiles(files, FileBucket.contract_1099)"></file-container>
    
    <file-container title="Expenses from Contract Work" v-if="info.contract_employee || fetchFiles(FileBucket.expenses).length > 0" :files="fetchFiles(FileBucket.expenses)" @upload="files => uploadFiles(files, FileBucket.expenses)"></file-container>
    
    <file-container title="Statement from Child Care Provider" v-if="info.child_care || fetchFiles(FileBucket.child_care_provider_statement).length > 0" :files="fetchFiles(FileBucket.child_care_provider_statement)" @upload="files => uploadFiles(files, FileBucket.child_care_provider_statement)"></file-container>
    
    <file-container title="Social Security 1099" v-if="info.received_social_security || fetchFiles(FileBucket.social_security_1099).length > 0" :files="fetchFiles(FileBucket.social_security_1099)" @upload="files => uploadFiles(files, FileBucket.social_security_1099)"></file-container>
    
    <file-container title="Retirement Account 1099-r" v-if="info.received_retirement_account_distributions || fetchFiles(FileBucket.retirement_account_1099r).length > 0" :files="fetchFiles(FileBucket.retirement_account_1099r)" @upload="files => uploadFiles(files, FileBucket.retirement_account_1099r)"></file-container>
    
    <file-container title="List of Medical Expenses" v-if="info.itemize_deductions || fetchFiles(FileBucket.mortgage_interest_1098).length > 0" :files="fetchFiles(FileBucket.mortgage_interest_1098)" @upload="files => uploadFiles(files, FileBucket.mortgage_interest_1098)"></file-container>
    
    <file-container title="List of medical expenses" v-if="info.itemize_deductions || fetchFiles(FileBucket.list_of_medical_expenses).length > 0" :files="fetchFiles(FileBucket.list_of_medical_expenses)" @upload="files => uploadFiles(files, FileBucket.list_of_medical_expenses)"></file-container>
    
    <file-container title="List of Charitable Contributions" v-if="info.itemize_deductions || fetchFiles(FileBucket.charitable_contributions).length > 0" :files="fetchFiles(FileBucket.charitable_contributions)" @upload="files => uploadFiles(files, FileBucket.charitable_contributions)"></file-container>
    
    
    <file-container title="Flow Through Business K1" v-if="info.sole_owner || fetchFiles(FileBucket.flow_through_business_k1).length > 0" :files="fetchFiles(FileBucket.flow_through_business_k1)" @upload="files => uploadFiles(files, FileBucket.flow_through_business_k1)"></file-container>
    
    <file-container title="Flow Through Business 1099" v-if="info.sole_owner && !info.filed_return_for_flow_through_entity || fetchFiles(FileBucket.flow_through_business_1099).length > 0" :files="fetchFiles(FileBucket.flow_through_business_1099)" @upload="files => uploadFiles(files, FileBucket.flow_through_business_1099)"></file-container>
    
    <file-container title="List of Flow Through Business Expenses" v-if="info.sole_owner && !info.filed_return_for_flow_through_entity || fetchFiles(FileBucket.flow_through_business_expenses).length > 0" :files="fetchFiles(FileBucket.flow_through_business_expenses)" @upload="files => uploadFiles(files, FileBucket.flow_through_business_expenses)"></file-container>
    
    <file-container title="List of Rental Property Incomes and Expenses" v-if="info.own_rental_property || fetchFiles(FileBucket.rental_property_expenses).length > 0" :files="fetchFiles(FileBucket.rental_property_expenses)" @upload="files => uploadFiles(files, FileBucket.rental_property_expenses)"></file-container>
    
    <file-container title="Royalty Payment 1099-r" v-if="info.receive_royalty_payments || fetchFiles(FileBucket.royalty_1099r).length > 0" :files="fetchFiles(FileBucket.royalty_1099r)" @upload="files => uploadFiles(files, FileBucket.royalty_1099r)"></file-container>
    
    <file-container title="Tuition 1099-t" v-if="info.paid_tuition || fetchFiles(FileBucket.tuition_1099t).length > 0" :files="fetchFiles(FileBucket.tuition_1099t)" @upload="files => uploadFiles(files, FileBucket.tuition_1099t)"></file-container>
    
    <file-container title="Student Loan Interest 1098-e" v-if="info.paid_student_loan_interest || fetchFiles(FileBucket.student_loan_interest_1098e).length > 0" :files="fetchFiles(FileBucket.student_loan_interest_1098e)" @upload="files => uploadFiles(files, FileBucket.student_loan_interest_1098e)"></file-container>
    
    <file-container title="List of Farm Incomes and Expenses" v-if="info.owned_a_farm || fetchFiles(FileBucket.farm_expenses).length > 0" :files="fetchFiles(FileBucket.farm_expenses)" @upload="files => uploadFiles(files, FileBucket.farm_expenses)"></file-container>
    
    <file-container title="Picture of Void Check for Direct Deposit" v-if="info.request_refund_direct_deposit || fetchFiles(FileBucket.void_check).length > 0" :files="fetchFiles(FileBucket.void_check)" @upload="files => uploadFiles(files, FileBucket.void_check)"></file-container>
    
    <!-- Get all remaining files  -->
    <file-container title="Additional Files" :files="fetchFiles(FileBucket.other)" @upload="files => uploadFiles(files, FileBucket.f_other)"></file-container>
  </div>
</template>

<script>
  import { Storage } from 'aws-amplify';
  import FileContainer from "@/components/organisms/FileContainer.vue"
  import LoadingBar from "@/components/atoms/LoadingBar.vue"
  const FileBucket = Object.freeze({
    prior_return: "prior_return",
    spouse_prior_return: "spouse_prior_return",
    divers_license: "divers_license",
    w2: "w2",
    investment_account_1099: "investment_account_1099",
    bank_account_1099: "bank_account_1099",
    contract_1099: "contract_1099",
    expenses: "expenses",
    child_care_provider_statement: "child_care_provider_statement",
    social_security_1099: "social_security_1099",
    retirement_account_1099r: "retirement_account_1099r",
    mortgage_interest_1098: "mortgage_interest_1098",
    list_of_medical_expenses: "list_of_medical_expenses",
    charitable_contributions: "charitable_contributions",
    flow_through_business_k1: "flow_through_business_k1",
    flow_through_business_1099: "flow_through_business_1099",
    flow_through_business_expenses: "flow_through_business_expenses",
    rental_property_expenses: "rental_property_expenses",
    royalty_1099r: "royalty_1099r",
    tuition_1099t: "tuition_1099t",
    student_loan_interest_1098e: "student_loan_interest_1098e",
    farm_expenses: "farm_expenses",
    void_check: "void_check",
    other: "other",
  })
  export default{
    components: { FileContainer, LoadingBar },
    data(){
      return{
        FileBucket
      }
    },
    computed: {
      info(){
        return this.$store.get("application");
      },
      // files_w2(){
      //   return this.$store.get("files/file_list_by_category", FileBucket.f_w2)
      // },
      // files_investment_account_1099(){
      //   return this.$store.get("files/file_list_by_category", FileBucket.f_w2)
      // },
      // files_1099(){
      //   return this.$store.get("files/file_list_by_category", FileBucket.f_1099)
      // },
      // files_other(){
      //   return this.$store.get("files/file_list_by_category", FileBucket.f_other)
      // },
    },
    methods: {
      fetchFiles(type){
        return this.$store.get("files/file_list_by_category", type)
      },
      uploadFiles(files, category){
        var uploads = files.map(file=>(
          this.$store.dispatch("files/saveFile", {file, category})
        ))
        Promise.all(uploads).then(responses=>{
          console.log(responses)
        })
      }
    }
  }
</script>
