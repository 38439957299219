export function normalizeFile(file){
  return Object.assign({}, newFile(), file)
}

export function newFile(){
  return {
    id: null,
    name: null,
    category: null,
    uploading: false,
    downloading: false,
    error_uploading: null,
    error_downloading: null
  }
}
