<style lang="scss" scoped>
  #sign-in{
    width: 100%;
    min-height: calc(100vh - 56px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(242,105,142);
    background: linear-gradient(155deg, rgba(242,105,142,1) 0%, rgba(170,65,226,1) 100%);
  }
</style>

<template>
  <div id="sign-in">
    <auth></auth>
  </div>
</template>

<script>
  import Auth from '@/components/organisms/Auth'
  export default{
    components: { Auth }
  }
</script>
