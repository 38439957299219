import { getUniqueID } from "@/lib/id.js"

export function newPayment(){
  return {
    id: getUniqueID(),
    pi: null,
    amount: null,
    completed: false,
  }
}

export const Items = Object.freeze({
  w2: Object.assign({}, newItem(), {
    name: "W2",
    cost: 200
  }),
  dependent: Object.assign({}, newItem(), {
    name: "Dependent",
    cost: 50
  }),
  stock_account_basic: Object.assign({}, newItem(), {
    name: "Stock Account",
    cost: 25
  }),
  additional_trade: Object.assign({}, newItem(), {
    name: "Additional Trade",
    cost: 1
  }),
  k1: Object.assign({}, newItem(), { // own Buisness but not sole owner
    name: "K1 With States",
    cost: 75
  }),
  sale_of_publicly_traded_partnership: Object.assign({}, newItem(), {
    name: "Sale of Publicly Traded Partnership",
    cost: 50
  }),
  itemize_deductions: Object.assign({}, newItem(), {
    name: "Itemized Deductions",
    cost: 25
  }),
  small_personal_buisness: Object.assign({}, newItem(), {
    name: "Small Personal Buisness",
    cost: 50
  }),
  rental_property: Object.assign({}, newItem(), {
    name: "Rental Property",
    cost: 25
  }),
})

export function newItem(){
  return {
    name: null,
    description: null,
    cost: 0,
  }
}
