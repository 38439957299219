import firebase from 'firebase'
import store from './store'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyAwIeWpAGO5T98OaknoM_x1sOWbC0GYWaI",
  authDomain: "goodbye1040-a8730.firebaseapp.com",
  databaseURL: "https://goodbye1040-a8730.firebaseio.com",
  projectId: "goodbye1040-a8730",
  storageBucket: "goodbye1040-a8730.appspot.com",
  messagingSenderId: "310580350870",
  appId: "1:310580350870:web:bc3808fc615465a88fd7b7",
  measurementId: "G-63RPN64CLF"
};
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

// collection references
const userCollection = db.collection('users')
const applicationCollection = db.collection('applications')

// export utils/refs
export {
  db,
  auth,
  storage,
  userCollection,
  applicationCollection,
}
