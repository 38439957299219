<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  #payment-page{
    width: 100%;
    min-height: calc(100vh - 56px);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #FCF9FB;
    .limiter{
      width: 500px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      padding-top: 100px;
      padding-bottom: 100px;
      .card{
        margin-bottom: 32px;
      }
      .no-payments{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-bottom: 32px;
        .fa{
          margin-right: 8px;
        }
      }
    }
  }
</style>

<template>
  <div id="payment-page">
    <div class="limiter">
      <pay v-if="$store.get('payment/pending_items') && $store.get('payment/pending_items').length > 0"></pay>
      <div v-else class="no-payments">
        You have no pending payments at this time.
      </div>
      <purchase v-for="payment in $store.get('payment/payments')" :payment="payment"></purchase>
    </div>
  </div>
</template>

<script>
  import Pay from "@/components/organisms/Pay.vue";
  import Purchase from "@/components/organisms/Purchase.vue";
  
  export default{
    components: { Pay, Purchase }
  }
</script>
