<style lang="scss" scoped>
  @import "~@/styles/constants";
  .textarea-container{
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    position: relative;
    .textarea{
      flex: 1;
      max-width: 100%;
      box-sizing: border-box;
      padding: 8px 16px;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #DDD;
      box-shadow: $z_depth_0;
      color: $input;
      font-family: 'Montserrat', sans-serif;
      background-color: rgba(0,0,0,0.03);
      &:focus {
        border-color: $input-border-active;
        outline: 0;
      }
      &.edited{
        border-color: $y !important;
      }
      &.password{
        font-size: 20px;
        padding: 5px 16px;
      }
      &.icon{
        padding: 8px 36px 8px 16px;
      }
    }
    .placeholder{
      position: absolute;
      top: 0px;
      left: 0px;
      padding: 3px 4px 1px 4px;
      pointer-events: none;
    }
  }
</style>

<template>
  <div class="textarea-container">
    <div :ref="'textarea:'+_uid" class="textarea" contenteditable="true" @focus="focus = true; $emit('focus', $event)" @blur="focus = false; $emit('blur', $event)" @input="update" @keyup="$emit('keyup', $event)" @keydown="$emit('keydown', $event)"></div>
    <div class="placeholder" v-if="!value && placeholder">
      {{placeholder}}
    </div>
  </div>
</template>

<script>
  export default{
    props:['value', 'placeholder'],
    mounted(){
      this.mountValue();
    },
    data(){
      return {
        focus: false,
      }
    },
    watch: {
      'value': function(){
        if(!this.focus || this.value == ""){
          this.mountValue();
        }
      }
    },
    methods: {
      mountValue(){
        this.$refs['textarea:'+this._uid].innerText = this.value;
      },
      update(event){
        this.$emit("update:value", event.target.innerText);
        this.$emit("input", event.target.innerText);
      }
    }
  }
</script>
