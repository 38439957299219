<style lang="scss" scoped>
  @import "~@/styles/constants.scss";
  #files-page{
    width: 100%;
    min-height: calc(100vh - 56px);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // background: rgb(242,105,142);
    // background: linear-gradient(155deg, rgba(242,105,142,1) 0%, rgba(170,65,226,1) 100%);
    .limiter{
      width: 650px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .back{
        margin-top: 32px;
        margin-bottom: 8px;
        display: inline-flex;
      }
      .card{
        width: 100%;
        margin-bottom: 64px;
        padding: 64px;
        background-color: #FEFEFE;
        .card-title{
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 40px;
        }
      }
      .done-area { 
        width: 100%;
        color: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
      	.fa { 
          font-size: 40px;
          margin-bottom: 16px;
      	}
      	.title { 
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 16px;
      	}
      	.message { 
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          margin-bottom: 32px;
      	}
      	.button { 
          font-size: 20px;
          font-weight: 500;
          background-color: #F5B132;
          height: 56px;
          width: 220px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          box-shadow: $z_depth_1;
          &:hover{
            cursor: pointer;
            background-color: #e3a32b;
          }
          &:active{
            background-color: #d69927;
            box-shadow: $z_depth_0;
          }
      	}
      }
    }
  }
</style>

<template>
  <div id="files-page">
    <loading-bar class="loading-bar" :progress="downlaod_progress"></loading-bar>
    <div class="limiter">
      <btn type="back" class="back" @click="$router.push({ name: 'GetStarted' })"><span class="fa fa-arrow-left"></span>Back</btn>
      <div class="card">
        <div class="card-title">
          We will need a few files from you.
        </div>
        <file-uploader></file-uploader>
      </div>
      <div class="done-area">
        <!-- <span class="fa fa-thumbs-up"></span>
        <div class="title">
          Thank You
        </div> -->
        <div class="message">
          Send us what you can.<br />
          Your CPA will help you with the rest.
        </div>
        <div class="button" @click="finshedUploading()">
          Upload
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Storage } from 'aws-amplify';
  import FileUploader from "@/components/organisms/FileUploader.vue"
  import LoadingBar from "@/components/atoms/LoadingBar.vue"
  import { ApplicationStatus } from "@/store/modules/application/status.schema.js"
  
  export default{
    components: { FileUploader, LoadingBar },
    computed: {
      downlaod_progress(){
        return 100 - this.$store.get("files/files_pending_download") * ( 100 / this.$store.get("files/file_count"))
      }
    },
    methods: {
      finshedUploading(){
        this.$store.commit("application/status", ApplicationStatus.upload_completed)
        this.$store.dispatch("sendUpdateEmail");
        // this.$store.dispatch("payment/addItemsToPending")
        // this.$router.push({ name: 'Payment' })
        this.$router.push({ name: 'Matching' })
      }
    }
  }
</script>
